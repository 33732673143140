import React, { useContext, useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import ModalSave from "../../common/ModelSave";
import { CancelButton, SaveButton } from "../../common/Button";
import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import classNames from "classnames";
library.add(fas);

const EditOffCanvance = (props) => {
  const id = props.show;
  const { postData, getData, IMG_URL, getDimension, Select2Data } =
    useContext(Context);
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const GetEditData = async () => {
    const response = await getData(`/birthday/${id}`);
    reset(response?.data);

    setVideoPreview(IMG_URL + response?.data?.video);
  };
  useEffect(() => {
    GetEditData();
  }, []);

  const {
    control,
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
    reset,
  } = useForm();

  const imageFile = watch("image");

  const onSubmit = async (data) => {
    try {
      const finalData = new FormData();
      finalData.append("message", data?.message);
      if (data?.video) {
        finalData.append("video", data?.video[0]);
      }
      finalData.append(
        "product_id",
        JSON.stringify(data?.product_id?.map((item) => item?.value))
      );
      const response = await postData(`/birthday/${id}`, finalData);

      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const [imagePreview, setImagePreview] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const [videoFile, setVideoFile] = useState(null);

  const handleVideoChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setVideoFile(e.target.files);
    }
  };

  const [products, setProduct] = useState([]);

  const GetAllProducts = async () => {
    const response = await getData("/all-products");

    if (response?.success) {
      setProduct(await Select2Data(response?.data, "product_id"));
    }
  };

  useEffect(() => {
    GetAllProducts();
  }, []);

  const [mediaPreview, setMediaPreview] = useState(null);

  const handleMediaChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type.split("/")[0]; // 'image' or 'video'
      const preview = URL.createObjectURL(file);

      console.log(fileType, "fileType");
      console.log(preview, "preview");

      setMediaPreview({
        src: preview,
        type: fileType,
      });
    }
  };
  const [charCount, setCharCount] = useState(0);
  const maxCharLimit = 100;

  const handleInputChange = (e) => {
    setCharCount(e.target.value.length);
  };

  return (
    <>
      <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Birthday
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={() => handleSubmit(onSubmit)} role="form">
            <Row>
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Message</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="message"
                          placeholder="Message"
                          className={classNames("", {
                            "is-invalid": errors?.message,
                          })}
                          {...register("message", {
                            required: "Message is required",
                            maxLength: {
                              value: maxCharLimit,
                              message: `Name cannot exceed ${maxCharLimit} characters`,
                            },
                          })}
                          maxLength={maxCharLimit}
                          onChange={handleInputChange}
                        />
                      </InputGroup>{" "}
                      <div className="text-end">
                        <small>{maxCharLimit} characters Limit </small>
                      </div>
                      {errors.message && (
                        <span className="text-danger">
                          {errors.message.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-start">
                    <Form.Label className="text-left">Video</Form.Label>
                    <Form.Group>
                      <Form.Control
                        className={classNames("", {
                          "is-invalid": errors?.video,
                        })}
                        type="file"
                        {...register("video", {
                          onChange: handleVideoChange,
                          // validate: async (value) => {
                          //   if (typeof value !== "string") {
                          //     const fileTypes = ["mp4", "mov", "avi"];
                          //     const fileType = value[0].name?.split(".")[1];
                          //     if (!fileTypes.includes(fileType)) {
                          //       return `Please upload a valid file format. (${fileTypes.join(
                          //         ", "
                          //       )})`;
                          //     }
                          //     const fileSize = Math.round(value[0].size / 1024);
                          //     if (fileSize > 10240) {
                          //       // 10MB
                          //       return "File size must be lower than 10MB";
                          //     }
                          //   }
                          // },
                        })}
                        accept=".mp4, .mov, .avi"
                      />
                    </Form.Group>
                    {errors.video && (
                      <span className="text-danger">
                        {errors.video.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label column sm={3} className="text-center">
                      Video Preview
                    </Form.Label>
                    <Col sm={9}>
                      {typeof getValues("video") === "string" ? (
                        <div className="video-preview-container">
                          <video
                            src={IMG_URL + getValues("video")}
                            alt="Preview"
                            className="video-preview"
                            style={{ width: "150px", height: "130px" }}
                            controls
                          />
                        </div>
                      ) : (
                        videoFile &&
                        videoFile.length > 0 && (
                          <div className="video-preview-container">
                            <video
                              src={URL.createObjectURL(videoFile[0])}
                              alt="Preview"
                              className="video-preview"
                              style={{ width: "150px", height: "130px" }}
                              controls
                            />
                          </div>
                        )
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={6}>
                <div className="col-lg-12">
                  <div className="field-bottom mb-3">
                    <Form.Label className="required">Products</Form.Label>
                    <Controller
                      name="product_id" // name of the field
                      control={control}
                      rules={{ required: "Select Categories" }}
                      render={({ field }) => (
                        <Select
                          isMulti
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.product_id
                                ? "red"
                                : baseStyles.borderColor,
                            }),
                          }}
                          {...field}
                          options={products}
                        />
                      )}
                    />
                    {errors.product_id && (
                      <span className="text-danger">
                        {errors.product_id.message}
                      </span>
                    )}
                  </div>
                </div>
              </Col>

              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>

                  <SaveButton
                    name={"save"}
                    handleSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
