import React, { useState, useEffect, useContext } from "react";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import { Row, Col, Form, InputGroup, Modal, Button } from "react-bootstrap";
import Select from "react-select";
import { RegxExpression } from "../../../utils/common";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { Uploader } from "uploader";
// import { UploadButton } from "react-uploader";
import { Context } from "../../../utils/context";
import Cookies from "js-cookie";
import Successfull_Modal from "../../common/Successfull_Modal/Successfull_Modal";

function StepSeven({ user_id, handleClose }) {
  const [modalSuccessShow, setModalSuccessShow] = React.useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { getData, IMG_URL, Select2Data, postData } = useContext(Context);
  // const [user_id, setUserId] = useState("");

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
    watch,
  } = useForm();

  const onSubmit = async (data) => {
    const formData = new FormData();
    if (data?.id) {
      formData.append("id", data?.id);
    }
    formData.append("user_id", user_id);
    formData.append("company_name", data?.company_name);
    formData.append("take_percent", data?.take_percent);
    formData.append("give_percent", data?.give_percent);

    try {
      const res = await postData(
        "/employee/seller-details/on-boarding-documents",
        formData
      );
      if (res?.success) {
        setTimeout(() => {
          setModalSuccessShow(false);

          handleClose(true);
        }, 2000);
        // props.nextStep();
      } else {
        // Handle the case when res.success is false
        console.error("Failed to submit data");
      }
    } catch (error) {
      // Handle any errors that occur during the request
      console.error("An error occurred while submitting data:", error);
    }
  };

  const getEditData = async () => {
    const res = await getData(`/employee/seller-details/on-boarding-documents`);
    if (res?.success) {
      reset(res?.data);
    }
  };

  useEffect(() => {
    getEditData();
  }, [user_id]);

  // useEffect(() => {
  //   setUserId(Cookies.get("user_id"));
  // }, []);

  return (
    <>
      <section className="personal-details-form userForm">
        <div className="details-form-holder">
          <div className="form-container">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="">
                <div className="row mb-3">
                  <div className="col-md-6">
                    <div className="field-bottom">
                      <div className="d-flex justify-content-between">
                        <Form.Label className="required">
                          Company name*
                        </Form.Label>
                      </div>
                      <div className="col-md-6">
                        <div className="field-bottom">
                          <Form.Control
                            type="text"
                            name="company_name"
                            placeholder="PAN No"
                            {...register("company_name", {
                              required: "PAN number is required",
                            })}
                            className={classNames("", {
                              "is-invalid": errors?.company_name,
                              "is-valid":
                                !errors?.company_name &&
                                getValues("company_name"),
                            })}
                          />

                          {errors.company_name && (
                            <span className="text-danger">
                              {errors.company_name.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="">
                <div className="row mb-3">
                  <div className="col-md-6">
                    <div className="field-bottom">
                      <Form.Group controlId="Last_Name">
                        <Form.Label className="required form-field">
                          Profit I Get
                        </Form.Label>

                        <Form.Control
                          type="number"
                          name="take_percent"
                          placeholder="Percent I Get"
                          {...register("take_percent", {
                            required: "Percent  is required",
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.take_percent,
                            "is-valid":
                              !errors?.take_percent &&
                              getValues("take_percent"),
                          })}
                        />

                        {errors.take_percent && (
                          <span className="text-danger">
                            {errors.take_percent.message}
                          </span>
                        )}
                      </Form.Group>
                    </div>
                  </div>{" "}
                </div>
              </div>
              <div className="">
                <div className="row mb-3">
                  <div className="col-md-6">
                    <div className="field-bottom">
                      <Form.Group controlId="Last_Name">
                        <Form.Label className="required form-field">
                          Profit I Can Give
                        </Form.Label>

                        <Form.Control
                          type="number"
                          name="give_percent"
                          placeholder="Percent I Can Give"
                          {...register("give_percent", {
                            required: "Percent  is required",
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.give_percent,
                            "is-valid":
                              !errors?.give_percent &&
                              getValues("give_percent"),
                          })}
                        />

                        {errors.give_percent && (
                          <span className="text-danger">
                            {errors.give_percent.message}
                          </span>
                        )}
                      </Form.Group>
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>
              <div className="">
                <div className=" text-end apply_now_btn">
                  {/* <Button onClick={props.prevStep} className="back-btn me-3">
                  Back
                </Button> */}
                  <Button
                    type="submit"
                    className="tabs-btn"
                    onClick={() => setModalSuccessShow(true)}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </section>
      <Successfull_Modal
        show={modalSuccessShow}
        onHide={() => setModalSuccessShow(false)}
        message={"On Boarding Document Submitted Successfully"}
        // subMessage={"fgdfg"}
      />
    </>
  );
}

export default StepSeven;
