import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import plus from "../../../Components/assets/icons/a1.png";
import colunms from "../../../Components/assets/icons/LINES.png";
import pen from "../../../Components/assets/icons/pen.png";
import basket from "../../../Components/assets/icons/basket.png";
import search1 from "../../../Components/assets/icons/search.png";
import top from "../../../Components/assets/icons/top.png";
import Table from "react-bootstrap/Table";
import circle from "../../assets/icons/circle.png";
import rigth from "../../assets/icons/rigth.png";
import save from "../../assets/icons/save.png";
import cancel from "../../assets/icons/cross.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../Header/Header";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Offcanvas from "react-bootstrap/Offcanvas";

import toast, { Toaster } from "react-hot-toast";
import Card from "react-bootstrap/Card";

import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import { Context } from "../../../utils/context";

import "../../Masters/datatable.css";
import Pagination_Holder from "../../common/Pagination_Holder/Pagination_Holder";
import Pagination from "react-bootstrap/Pagination";

library.add(fas);

const AddOffCanvance = (props) => {
  const { postData, getData, usertype } = useContext(Context);
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [permission, setPermission] = useState([]);
  const [allChecked, setAllChecked] = useState([]);

  const [formData, setFormData] = useState({
    name: "",
    permission_id: [],
  });

  const handleSelectAll = async () => {
    await setFormData({ ...formData, permission_id: allChecked });
  };

  const handleCheckboxChange = async (
    permissionId,
    value = null,
    checked = null
  ) => {
    if (value === "selectAll") {
      if (checked) {
        handleSelectAll();
      } else {
        await setFormData({ ...formData, permission_id: [] });
      }
    } else {
      const updatedPermissions = [...formData.permission_id];

      if (updatedPermissions.includes(permissionId)) {
        updatedPermissions.splice(updatedPermissions.indexOf(permissionId), 1);
      } else {
        updatedPermissions.push(permissionId);
      }

      setFormData({ ...formData, permission_id: updatedPermissions });
    }
  };

  const validateForm = () => {
    let errors = {};

    // const regex = /^[A-Za-z]+(\s[A-Za-z]+)*$/;
    const regex = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;

    if (!formData.name.trim()) {
      errors.name = "Role Name is required";
    } else if (!regex.test(formData.name.trim())) {
      errors.name = "Enter Valid Role ";
    }

    if (formData?.permission_id.length == 0) {
      errors.permission_id = "Select Min 1 Permission is required";
    }

    return errors;
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const response = await postData("/employee/role", formData);

        if (response.success) {
          setShowModal(true);
          setTimeout(() => {
            setShowModal(false);
            props.handleClose();
          }, 1000);
        } else {
          setShowErrorModal(true);
          setTimeout(() => {
            setShowErrorModal(false);
            props.handleClose();
          }, 1000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
  };

  const GetAllPermission = async () => {
    const response = await getData(
      "/role-and-permission/masters/all-permission"
    );
    setPermission(response?.data);
    const newData = response?.data;
    if (newData) {
      const newIds = newData.map((d) => d?.id);
      setAllChecked(newIds);
    }
  };

  useEffect(() => {
    GetAllPermission();
  }, []);

  const [charCount, setCharCount] = useState(0);
  const maxCharLimit = 100;

  const handleInputChange = (e) => {
    setCharCount(e.target.value.length);
  };

  return (
    <>
      {/* <Offcanvas
        show={props.show}
        style={{ width: "80%", height: "80%", margin: "auto" }}
        placement={"top"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card.Body>
              <Card.Title>Add Role</Card.Title>
              <hr />
              <Row>
                <Col md={12}>
                  <Row className="">
                    <Col xxl={12} xl={12} md={10}>
                      <Form onSubmit={handleSubmit} role="form">
                        <Row>
                          <Col lg={12} md={12} className=" mx-auto Add-content">
                            <Row>
                              <Col md={12}>
                                <div className="main-form-section mt-5">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Role
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="text"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            placeholder="Role Name"
                                            id="inputEmail3"
                                          />
                                        </InputGroup>
                                        {errors.name && (
                                          <span style={errorStyle}>
                                            {errors.name}
                                          </span>
                                        )}
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>

                              <Col md={12}>
                                <div className="main-form-section mt-5">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Permissions Select All
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <Form.Check
                                          type="checkbox"
                                          value="selectAll"
                                          onChange={(e) =>
                                            handleCheckboxChange(
                                              e,
                                              "selectAll",
                                              e.target.checked
                                            )
                                          }
                                        />
                                        {errors.permission_id && (
                                          <span style={errorStyle}>
                                            {errors.permission_id}
                                          </span>
                                        )}
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>

                              <Col md={12}>
                                <div className="main-form-section mt-5">
                                  <div className="row">
                                    {permission &&
                                      permission.map((data, index) => (
                                        <div className="col-md-3" key={data.id}>
                                          <Form.Group>
                                            <Form.Check
                                              type="checkbox"
                                              label={data.name}
                                              checked={
                                                formData?.permission_id &&
                                                formData?.permission_id?.includes(
                                                  data?.id
                                                )
                                              }
                                              onChange={() =>
                                                handleCheckboxChange(data.id)
                                              }
                                              id={`checkbox-${data.id}`}
                                            />
                                          </Form.Group>
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <Row className="mt-5 pb-3">
                              <div className="d-flex justify-content-center">
                                <Link>
                                  <Button
                                    onClick={props.handleClose}
                                    type="button"
                                    variant="danger"
                                    className="btn btn-cancel me-2"
                                  >
                                    <img
                                      src={cancel}
                                      className="cancel-img"
                                      alt=""
                                    />{" "}
                                    Cancel
                                  </Button>
                                </Link>
                                <Button
                                  variant="success"
                                  type="submit"
                                  onClick={handleSubmit}
                                  className="btn btn-save"
                                >
                                  <img
                                    src={save}
                                    className="save-img me-2"
                                    alt=""
                                  />{" "}
                                  Save
                                </Button>
                              </div>
                            </Row>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas> */}

      <Offcanvas
        show={props.show}
        style={{ width: "80%", height: "100%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card.Body>
              <Card.Title>Add Role</Card.Title>
              <hr />
              <Row>
                <Col md={12}>
                  <Row className="">
                    <Col xxl={12} xl={12} md={10}>
                      <Form onSubmit={handleSubmit} role="form">
                        <Row>
                          <Col lg={12} md={12} className=" mx-auto Add-content">
                            <Row>
                              <Col md={12}>
                                <div className="main-form-section mt-5">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Role
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="text"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            placeholder="Role Name"
                                            id="inputEmail3"
                                          />
                                        </InputGroup>
                                        {errors.name && (
                                          <span style={errorStyle}>
                                            {errors.name}
                                          </span>
                                        )}
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>

                              <Col md={12}>
                                <div className="main-form-section mt-5">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Permissions Select All
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <Form.Check
                                          type="checkbox"
                                          value="selectAll"
                                          onChange={(e) =>
                                            handleCheckboxChange(
                                              e,
                                              "selectAll",
                                              e.target.checked
                                            )
                                          }
                                        />
                                        {errors.permission_id && (
                                          <span style={errorStyle}>
                                            {errors.permission_id}
                                          </span>
                                        )}
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>

                              <Col md={12}>
                                <div className="main-form-section mt-5">
                                  <div className="row">
                                    {permission &&
                                      permission.map((data, index) => (
                                        <div className="col-md-3" key={data.id}>
                                          <Form.Group>
                                            <Form.Check
                                              type="checkbox"
                                              label={data.name}
                                              checked={
                                                formData?.permission_id &&
                                                formData?.permission_id?.includes(
                                                  data?.id
                                                )
                                              }
                                              onChange={() =>
                                                handleCheckboxChange(data.id)
                                              }
                                              id={`checkbox-${data.id}`}
                                            />
                                          </Form.Group>
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <Row className="mt-5 pb-3">
                              <div className="d-flex justify-content-center">
                                <Link>
                                  <Button
                                    onClick={props.handleClose}
                                    type="button"
                                    variant="danger"
                                    className="btn btn-cancel me-2"
                                  >
                                    <img
                                      src={cancel}
                                      className="cancel-img"
                                      alt=""
                                    />{" "}
                                    Cancel
                                  </Button>
                                </Link>
                                <Button
                                  variant="success"
                                  type="submit"
                                  onClick={handleSubmit}
                                  className="btn btn-save"
                                >
                                  <img
                                    src={save}
                                    className="save-img me-2"
                                    alt=""
                                  />{" "}
                                  Save
                                </Button>
                              </div>
                            </Row>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>

      {/* <!-- Modal --> */}
      <div className="save-modal">
        <div
          className={`modal fade ${showModal ? "show" : ""}`}
          style={{ display: showModal ? "block" : "none" }}
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="circle justify-content-end">
                  <img src={circle} className="circle-img mb-2" alt="" />
                </div>
                <h1 className="add-success text-center">
                  Role Added Successfully
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal --> */}
      <div className="save-modal">
        <div
          className={`modal fade ${showErrorModal ? "show" : ""}`}
          style={{ display: showErrorModal ? "block" : "none" }}
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="circle justify-content-end">
                  <img src={circle} className="circle-img mb-2" alt="" />
                </div>
                <h1 className="add-success text-center">Role Already Exits</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
// ***************************************************************************************************************************************************************************
const EditOffCanvance = (props) => {
  const { postData, getData, RequiredIs } = useContext(Context);

  const id = props.show;
  const [data, setData] = useState();

  const [formData, setFormData] = useState({
    role_id: "",
    name: "",
    permission_id: [],
  });

  const [permissions, setPermissions] = useState([]);
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [allChecked, setAllChecked] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState(false);

  const validateForm = () => {
    let errors = {};

    const regex = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;

    if (!formData?.name.trim()) {
      errors.name = "Role Name is required";
    } else if (!regex.test(formData?.name.trim())) {
      errors.name = "Enter Valid Role ";
    }

    if (selectedPermissions?.length == 0) {
      errors.permission_id = "Select Min 1 Permission is required";
    }

    return errors;
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target?.name]: e.target.value });
  };

  const handleSelectAll = async () => {
    setSelectedPermissions(allChecked);
    await setFormData({ ...formData, permission_id: allChecked });
  };

  const handleCheckboxChange = async (
    permissionId,
    value = null,
    checked = null
  ) => {
    if (value === "selectAll") {
      if (checked) {
        handleSelectAll();
      } else {
        await setFormData({ ...formData, permission_id: [] });
        setSelectedPermissions([]);
      }
    } else {
      const isSelected = selectedPermissions.includes(permissionId);

      if (isSelected) {
        const updatedSelectedPermissions = selectedPermissions.filter(
          (id) => id !== permissionId
        );
        setSelectedPermissions(updatedSelectedPermissions);
        setFormData({ ...formData, permission_id: updatedSelectedPermissions });
      } else {
        const updatedSelectedPermissions = [
          ...selectedPermissions,
          permissionId,
        ];
        setSelectedPermissions(updatedSelectedPermissions);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        formData.permission_id = selectedPermissions;

        const response = await postData(`/employee/role/${id}`, formData);

        if (response.success) {
          setShowModal(true);
          setTimeout(() => {
            setShowModal(false);
            props.handleClose();
          }, 1000);
        } else {
          setShowErrorModal(true);
          setTimeout(() => {
            setShowErrorModal(false);
            props.handleClose();
          }, 1000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const GetEditData = async () => {
    const response = await getData(`/employee/role/${id}`);
    const roleData = response?.data?.role;

    const selectedPermissionIds = Array.isArray(
      response?.data?.selectedPermissionIds
    )
      ? response.data.selectedPermissionIds
      : [];

    setData(roleData);
    setSelectedPermissions(selectedPermissionIds);
    setFormData(roleData);
  };

  useEffect(() => {
    GetEditData();
  }, []);

  const getPermissions = async () => {
    try {
      const permissionResponse = await getData(
        "/role-and-permission/masters/all-permission"
      );
      setPermissions(permissionResponse.data);
      const newData = permissionResponse.data;
      if (newData) {
        const newIds = newData.map((d) => d?.id);
        setAllChecked(newIds);
      }
    } catch (error) {
      console.error("Error fetching permissions:", error);
    }
  };

  useEffect(() => {
    getPermissions();
  }, []);

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
  };

  return (
    <>
      <Offcanvas
        show={props.show}
        style={{ width: "80%", height: "100%" }}
        placement={"end"}
        onHide={props.handleClose}
      >
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card.Body>
              <Card.Title>Edit Role</Card.Title>
              <hr />
              <Row>
                <Col md={12}>
                  <Row className="">
                    <Col xxl={12} xl={12} md={10}>
                      <Form onSubmit={handleSubmit} role="form">
                        <Row>
                          <Col lg={12} md={12} className=" mx-auto Add-content">
                            <Row>
                              <Col md={12}>
                                <div className="main-form-section mt-5">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Role
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <InputGroup>
                                          <Form.Control
                                            type="text"
                                            name="name"
                                            value={formData?.name}
                                            disabled={RequiredIs?.roles?.includes(
                                              id
                                            )}
                                            onChange={handleChange}
                                            placeholder="Role Name"
                                            id="inputEmail3"
                                          />
                                        </InputGroup>
                                        {errors.name && (
                                          <span style={errorStyle}>
                                            {errors.name}
                                          </span>
                                        )}
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>

                              <Col md={12}>
                                <div className="main-form-section mt-5">
                                  <Row className="justify-content-center">
                                    <Form.Label column sm={3}>
                                      Permissions Select All
                                    </Form.Label>
                                    <Col sm={6}>
                                      <Form.Group>
                                        <Form.Check
                                          type="checkbox"
                                          value="selectAll"
                                          checked={
                                            selectedPermissions.length ===
                                            allChecked.length
                                              ? true
                                              : false
                                          }
                                          // label={data.name}
                                          onChange={(e) =>
                                            handleCheckboxChange(
                                              e,
                                              "selectAll",
                                              e.target.checked
                                            )
                                          }
                                        />
                                        {errors.permission_id && (
                                          <span style={errorStyle}>
                                            {errors.permission_id}
                                          </span>
                                        )}
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>

                              <Col md={12}>
                                <div className="main-form-section mt-5">
                                  <div className="row">
                                    {Array.isArray(permissions) &&
                                      permissions.map((data, index) => (
                                        <div className="col-md-3" key={data.id}>
                                          <Form.Group>
                                            <Form.Check
                                              type="checkbox"
                                              label={data.name}
                                              onChange={() =>
                                                handleCheckboxChange(data.id)
                                              }
                                              checked={
                                                selectedPermissions &&
                                                selectedPermissions?.includes(
                                                  data?.id
                                                )
                                              }
                                              id={`checkbox-${data.id}`}
                                            />
                                          </Form.Group>
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <Row className="mt-5 pb-3">
                              <div className="d-flex justify-content-center">
                                <Button
                                  type="button"
                                  variant="danger"
                                  onClick={() => props.handleClose()}
                                  className="btn btn-cancel me-2"
                                >
                                  <img
                                    src={cancel}
                                    className="cancel-img"
                                    alt=""
                                  />{" "}
                                  Cancel
                                </Button>

                                <Button
                                  variant="success"
                                  type="submit"
                                  onClick={handleSubmit}
                                  className="btn btn-save"
                                >
                                  <img
                                    src={save}
                                    className="save-img me-2"
                                    alt=""
                                  />{" "}
                                  Save
                                </Button>
                              </div>
                            </Row>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Offcanvas.Body>
      </Offcanvas>

      {/* <!-- Modal --> */}
      <div className="save-modal">
        <div
          className={`modal fade ${showModal ? "show" : ""}`}
          style={{ display: showModal ? "block" : "none" }}
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="circle justify-content-end">
                  <img src={circle} className="circle-img mb-2" alt="" />
                </div>
                <h1 className="add-success text-center">
                  Role Updated Successfully
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal --> */}
      <div className="save-modal">
        <div
          className={`modal fade ${showErrorModal ? "show" : ""}`}
          style={{ display: showErrorModal ? "block" : "none" }}
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="circle justify-content-end">
                  <img src={circle} className="circle-img mb-2" alt="" />
                </div>
                <h1 className="add-success text-center">Role Already Exits</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
// ********************************************************************************************************************************************************

const Tables = () => {
  const {
    getData,
    editStatusData,
    deleteData,
    ErrorNotify,
    isAllow,
    Per_Page_Dropdown,
    RequiredIs,
    usertype,
  } = useContext(Context);

  const [onPageChange, setonPageChange] = useState(1);

  const [totalPages, settotalPages] = useState();

  const handlePageChange = (pageNumber) => {
    console.log(pageNumber, "pageNumber");

    setonPageChange(pageNumber);
  };

  const paginationItems = [];
  for (let page = 1; page <= totalPages; page++) {
    paginationItems.push(
      <Pagination.Item
        key={page}
        active={page === onPageChange}
        onClick={() => handlePageChange(page)}
      >
        {page}
      </Pagination.Item>
    );
  }

  const [data, setData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(5);
  const [search, setSearch] = useState("");
  const [reset, setReset] = useState();
  const [show, setShowAdd] = useState(false);
  const [show1, setShowEdit] = useState(0);
  const [option, setOption] = useState();

  const [changeStatus, setChangeStatus] = useState();

  const getDataAll = async () => {
    const response = await getData(
      `/employee/role?page=${onPageChange}&per_page=${perPage}&term=${search}`
    );
    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);
    setSearch(response?.data?.search_name);
    settotalPages(response?.data?.totalPages);
    // setCurrentPage(response?.data?.);
    setOption(await Per_Page_Dropdown(response?.data?.totalEntries));
    const newData = response?.data?.data;
    if (newData) {
      const newIds = newData.map((d) => d?.id);
      setAllChecked(newIds);
    }
  };

  useEffect(() => {
    getDataAll();
  }, [changeStatus, perPage, reset, show, show1, onPageChange]);

  const ChangeStatus = async (id) => {
    const response = await editStatusData(`/employee/role/${id}`);
    setChangeStatus(response);
  };

  // Modal function
  const handleClose = () => setShowAdd(false);
  const handleShow = () => setShowAdd(true);

  const handleClose1 = () => setShowEdit(0);
  const handleShow1 = (id) => {
    setShowEdit(id);
  };

  // Select All Functionality
  const [selectAllChecked, setSelectAllChecked] = useState([]);
  const [allChecked, setAllChecked] = useState([]);

  const handleSelectAll = async () => {
    await setSelectAllChecked(allChecked);
  };

  const handleChange = async (e) => {
    const { value, checked } = e.target;

    if (value === "selectAll") {
      if (checked) {
        handleSelectAll();
      } else {
        await setSelectAllChecked([]);
      }
    } else {
      if (checked) {
        await setSelectAllChecked([...selectAllChecked, Number(value)]);
      } else {
        await setSelectAllChecked(
          selectAllChecked.filter((e) => e !== Number(value))
        );
      }
    }
  };

  // Delete module
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordToDeleteId, setRecordToDeleteId] = useState(null);
  const [recordToDeleteName, setRecordToDeleteName] = useState(null);

  const showDeleteRecord = async (id, name) => {
    setShowDeleteModal(true);
    setRecordToDeleteId(id);
    setRecordToDeleteName(name);
  };

  const handleDeleteRecord = async () => {
    setShowDeleteModal(false);
    if (recordToDeleteId) {
      const response = await deleteData(`/employee/role/${recordToDeleteId}`);
      // notify("error", "Deleted Succefully");

      ErrorNotify(recordToDeleteName);

      setRecordToDeleteId(null);
      setRecordToDeleteName(null);
      setChangeStatus(response);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setRecordToDeleteId(null);
    setRecordToDeleteName(null);
  };

  //  column hide and show.....
  const [visible, setVisibel] = useState({
    col0: true,
    col1: true,
    col2: true,
    col3: true,
    col4: true,
  });

  //toggle columnns.........
  const toggleColumn = (event, columnName) => {
    event.preventDefault();
    setVisibel((prev) => ({
      ...prev,
      [columnName]: !prev[columnName],
    }));
  };

  return (
    <>
      <div className="main-advancedashboard">
        <Header title={"Role"} link={"/employee/role"} />
        <section className="AdvanceDashboard">
          <div className="col-xxl-12 col-xl-12  ">
            <div className="row MainRowsec">
              <section className="Tabels tab-radio tab-radio mt-3">
                <div className="">
                  <div className="row ">
                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mt-lg-0 mt-2">
                      <div className="add">
                        <Link type="button" className="btn btn-add pe-3">
                          <div onClick={() => handleShow()}>
                            <img src={plus} className="plus me-2 ms-0" alt="" />
                            Add Role
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mt-lg-0 mt-2">
                      <div className="add">
                        <div className="dropdown">
                          <button
                            className="btn btn-add dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img
                              src={colunms}
                              className="columns me-2 "
                              alt=""
                            />
                            Column Selection{" "}
                            <img src={top} className="top ms-1" alt="" />{" "}
                          </button>
                          <ul className="dropdown-menu">
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col1")}
                                href="#"
                              >
                                Sr. No.
                                {visible?.col1 ? (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col2")}
                                href="#"
                              >
                                Role Name
                                {visible?.col2 ? (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>

                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col3")}
                                href="#"
                              >
                                Status
                                {visible?.col3 ? (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col4")}
                                href="#"
                              >
                                Action
                                {visible?.col4 ? (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="border-line my-3"></div>
                    <div className="row">
                      <div className=" col-12">
                        <div className="d-flex mb-3 align-items-center">
                          <div className="show me-2">
                            <p className="show m-0">Show</p>
                          </div>
                          <div className="number me-2">
                            <select
                              className="form-select form-select-sm"
                              aria-label=".form-select-sm example"
                              onChange={(e) => setperPage(e.target.value)}
                            >
                              {option?.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="entries me-5">
                            <p className="show m-0">entries</p>
                          </div>
                          <div className="col-xxl-2 col-xl-3  col-lg-4  col-md-6 col-12 ">
                            <div className="sowing me-2">
                              <p className="show m-0">{`Showing ${Math.min(
                                (currentPage - 1) * perPage + 1
                              )} to ${Math.min(
                                currentPage * perPage,
                                data?.data?.totalEntries
                              )} of ${data?.data?.totalEntries} entries`}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className=" col-12">
                        <div className="row align-items-end">
                          <div className=" col-xxl-2 col-xl-3  col-lg-4  col-md-6 col-12 mb-2">
                            <input
                              type="text"
                              className="form-control"
                              id=""
                              value={search}
                              placeholder="Role Name"
                              onChange={(e) => {
                                setSearch(e.target.value);
                                handlePageChange(1);
                              }}
                            />
                          </div>
                          <div className="col-xxl-1 col-xl-2  col-lg-2  col-md-2 col-12 mb-2">
                            <Button
                              type="button"
                              onClick={getDataAll}
                              className="btn btn-search btn btn-primary w-100"
                            >
                              <img src={search1} className="search" alt="" />
                            </Button>
                          </div>

                          <div className="col-xxl-1 col-xl-2  col-lg-2  col-md-2 col-12 mb-2">
                            <button
                              type="button"
                              onClick={() => {
                                setSearch("");
                                setReset(!reset);
                              }}
                              className="btn btn-reset w-100"
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border-line my-3"></div>
                  <div className="row mt-3">
                    <div className="data table-responsive">
                      <Table striped bordered hover responsive center>
                        <thead>
                          <tr className="">
                            {visible.col1 && <th className="sr">Sr. No.</th>}
                            {visible.col2 && (
                              <th className="tax-name">Role Name</th>
                            )}
                            {/* {visible.col3 && <th className="tax-name">Status</th>} */}
                            {visible.col4 && <th className="active">Action</th>}
                          </tr>
                        </thead>

                        <tbody>
                          {/* {isAllow.includes(1) ? ( */}
                          {data?.data?.data?.length > 0 ? (
                            data.data.data.map((d, index) => {
                              const paginatedIndex =
                                (onPageChange - 1) * perPage + index + 1;
                              return (
                                <tr className="" key={index}>
                                  {visible.col1 && <td>{paginatedIndex}.</td>}{" "}
                                  {visible.col2 && <td>{d?.name}</td>}
                                  {/* {visible.col3 && (
                                  <td>
                                    {d?.id === 1 ? (
                                      <></>
                                    ) : (
                                      <div className="form-check form-switch">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          role="switch"
                                          checked={d.status}
                                          onChange={() => {
                                            ChangeStatus(d.id);
                                          }}
                                          id={`flexSwitchCheckDefault${d?.id}`}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={`flexSwitchCheckDefault${d?.id}`}
                                        >
                                          {d.status ? "Active" : "Inactive"}
                                        </label>
                                      </div>
                                    )}
                                  </td>
                                )} */}
                                  {visible.col4 && (
                                    <td>
                                      <div className="d-flex">
                                        {d.id !== 2 && (
                                          <Button
                                            onClick={() => handleShow1(d?.id)}
                                            type="button"
                                            className="btn btn-primary me-1"
                                          >
                                            <img
                                              src={pen}
                                              className="pen"
                                              alt=""
                                            />
                                          </Button>
                                        )}

                                        {d.id !== 2 &&
                                          d.id !== 3 &&
                                          d.id !== 6 && (
                                            <button
                                              onClick={() => {
                                                showDeleteRecord(
                                                  d?.id,
                                                  d?.name
                                                );
                                              }}
                                              type="button"
                                              className="btn btn-danger"
                                            >
                                              <img
                                                src={basket}
                                                className="pen"
                                                alt=""
                                              />
                                            </button>
                                          )}
                                      </div>
                                    </td>
                                  )}
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="10" className="text-center">
                                Data not available
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>{" "}
                      <Pagination_Holder
                        onPageChange={currentPage}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                      />
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>

      {show ? (
        <AddOffCanvance
          handleClose={handleClose}
          setShow={setShowAdd}
          show={show}
        />
      ) : (
        ""
      )}

      {show1 ? (
        <EditOffCanvance
          handleClose={handleClose1}
          setShow={setShowEdit}
          show={show1}
        />
      ) : (
        ""
      )}

      {/* <!-- Modal Delete --> */}
      <div className="upload-modal">
        <div
          className={`modal fade ${showDeleteModal ? "show" : ""}`}
          style={{ display: showDeleteModal ? "block" : "none" }}
          id="exampleModaldel"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <h1 className="add-success text-center  mt-2">
                  Are you sure ?
                </h1>
                <p>
                  Do You Really Want to Delete These Record?
                  <br />
                  Dependent Data Also Be Deleted And
                  <br /> This Process CanNot Be Undone{" "}
                </p>
                <div className="button-holder text-center mt-2">
                  <button
                    className="btn btn-yes me-2"
                    onClick={handleDeleteRecord}
                  >
                    Yes
                  </button>
                  <button className="btn btn-no" onClick={handleDeleteCancel}>
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster position="top-right" />
    </>
  );
};

export default Tables;
