import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { GetSubscription } from "../../../utils/apis/master/Master";
import { formatDate } from "../../../utils/common";

const SubscriptionModal = (props) => {
  const [subscription, setSubscription] = useState();

  const getSubscription = async () => {
    const res = await GetSubscription();
    if (res?.success) {
      setSubscription(res?.data);
      console.error("Error fetching  data:", res?.error);
    }
  };

  useEffect(() => {
    getSubscription();
  }, []);

  return (
    <div>
      {" "}
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {subscription ? "My Subscription" : "No Subscription"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {subscription ? (
              <Row>
                <Col md={6} sm={6} xs={12}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Vendor Name</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder={subscription?.user?.first_name}
                      disabled
                    />
                  </Form.Group>
                </Col>
                <Col md={6} sm={6} xs={12}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Transaction Id</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder={subscription?.transaction_id}
                      disabled
                    />
                  </Form.Group>
                </Col>
                <Col md={6} sm={6} xs={12}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Plan</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder={subscription?.plan}
                      disabled
                    />
                  </Form.Group>
                </Col>
                <Col md={6} sm={6} xs={12}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Transaction Date</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder={
                        subscription?.createdAt
                          ? formatDate(subscription?.createdAt)
                          : ""
                      }
                      disabled
                    />
                  </Form.Group>
                </Col>

                <Col md={6} sm={6} xs={12}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Subscription Start Date</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder={
                        subscription?.user?.commission_s_date
                          ? formatDate(subscription?.user?.commission_s_date)
                          : ""
                      }
                      disabled
                    />
                  </Form.Group>
                </Col>

                <Col md={6} sm={6} xs={12}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Subscription End Date</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder={
                        subscription?.user?.commission_e_date
                          ? formatDate(subscription?.user?.commission_e_date)
                          : ""
                      }
                      disabled
                    />
                  </Form.Group>
                </Col>
              </Row>
            ) : (
              ""
            )}
          </Form>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer> */}
      </Modal>
    </div>
  );
};

export default SubscriptionModal;
