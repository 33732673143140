export const Per_Page_Dropdown = async (data) => {
  const a = [];
  for (let i = 5; i <= data; i *= 2) {
    a.push(i);
  }
  a.push(data);

  return a;
};

export const RequiredIs = {
  roles: [1],
  service_term: [1, 2, 3, 4],
  service_type: [1, 2, 3],
  product_type: [1, 2, 3, 4],
};

export const Select2Data = async (data, name, other = false) => {
  const result = data.map((data) => ({
    value: data?.id,
    label: data?.name,
    name: name,
  }));

  if (other) {
    result.push({ value: "0", label: "Other", name: name });
  }
  return result;
};

export const SelectProduct = async (data, name, other = false) => {
  const result = data.map((data) => ({
    value: data?.id,
    label: data?.name,
    name: name,
    type: data?.type,
  }));

  if (other) {
    result.push({ value: "0", label: "Other", name: name, type: data?.type });
  }
  return result;
};

export const SelectUsers = async (data, name, other = false) => {
  const result = data.map((data) => ({
    value: data?.id,
    label: data?.first_name,
    name: name,
  }));

  if (other) {
    result.push({ value: "0", label: "Other", name: name });
  }
  return result;
};

export const SelectDelivery = async (data, first_name, other = false) => {
  const result = data.map((data) => ({
    value: data?.id,
    label: data?.name,
    name: first_name,
  }));

  if (other) {
    result.push({ value: "0", label: "Other", name: first_name });
  }
  return result;
};

export const SelectSingleVarient = async (data, v_name, other = false) => {
  const result = data.map((data) => ({
    value: data?.id,
    label: data?.v_name,
    name: v_name,
  }));

  if (other) {
    result.push({ value: "0", label: "Other", name: v_name });
  }
  return result;
};

export const SelectMultipleMainVarient = async (
  data,
  varient_name,
  other = false
) => {
  const result = data.map((data) => ({
    value: data?.id,
    label: data?.varient_name,
    name: varient_name,
  }));

  if (other) {
    result.push({ value: "0", label: "Other", name: varient_name });
  }
  return result;
};

export const SelectMultipleSubVarient = async (
  data,
  varient_sub_name,
  other = false
) => {
  const result = data.map((data) => ({
    value: data?.id,
    label: data?.varient_sub_name,
    name: varient_sub_name,
  }));

  if (other) {
    result.push({ value: "0", label: "Other", name: varient_sub_name });
  }
  return result;
};

export const SelectReportAnalysisData = async (data, name, other = false) => {
  const result = data.map((data) => ({
    value: data?.id,
    label: data?.name,
    name: data?.days,
  }));

  if (other) {
    result.push({ value: "0", label: "Other", name: data?.days });
  }
  return result;
};

export function formatDate(dateString) {
  const date = new Date(dateString);
  return date.toISOString().split("T")[0];
}

export const getDimension = async (file) => {
  let reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException("Problem parsing input file."));
    };

    reader.onload = () => {
      var image = new Image();
      image.src = reader.result;
      image.onload = function () {
        resolve({ width: this.width, height: this.height });
      };
    };
    reader.readAsDataURL(file);
  });
};

export const IDS = {
  Roles: [1],
  Country: [9],
  State: [13],
  City: [17],
  Pincode: [21],
  CountryCode: [25],
  Category: [29],
  SubCategory: [33],
  ChildSubCategory: [37],
  FAQCategory: [41],
  FAQ: [45],
  Brands: [49],
  TaxType: [53],
  TaxPercentage: [57],
  CouponCode: [61],
  Blog: [65],
  Banner: [69],
  WebHeader: [73],
  AppSetup: [77],
  SellerRequest: [82],
  SellerDetails: [81],
  SellerRejected: [84],
  Product: [87],
  WhyChoose: [91],
  HappyCustomer: [94],
  OurStory: [99],
  AboutBanner: [103],

  Order: [107],

  OrderToRefund: [113],

  MembershipFeatures: [213],
  Notification: [217],
  WorkModule: [221],
  JobType: [225],
  Department: [229],
  Qualification: [233],
  Jobs: [237],
  Delivery: [241],
  Supplier: [245],
  OrderPurchase: [249],
  Finance: [253],
  WalletWithdrawl: [257],
  WalletHistory: [296],
  queryComplaint: [297],
  PrivacyPolicy: [258],
  CategoryBanner: [262],

  TermAndCondition: [266],
  AdvertisementBanner: [270],

  ReportAnalysis: [274],
  BlogVideo: [278],
  MembershipDetails: [282],
  CustomersDetails: [286],
  ContactUs: [287],
  CareerApplication: [288],
  Inquire: [289],
  NewsLatterSubscriber: [290],
  ProductReview: [291],
  Birthday: [292],

  // Roles: [1, 2],
  // Country: [6, 7, 8, 9],
  // State: [11, 12, 13, 14],
  // City: [16, 17, 18, 19],
  // Pincode: [21, 22, 23, 24],
  // Restaurent: [26, 27, 28, 29],
  // Category: [31, 32, 33, 34],
  // HomeBanner: [36, 37, 38, 39],
  // TiffinBanner: [41, 42, 43, 44],
  // TiffinCategory: [46, 47, 48, 49],
  // FoodType: [51, 52, 53, 54],
  // AdminDishes: [56, 57, 58, 59],
  // RecommendedDishes: [61, 62, 63, 64],
  // Dishes: [66, 67, 68, 69],

  // usertype: {
  //   Restaurent: "Restaurent",
  //   Admin: "Admin",
  // },
  // order_type: {
  //   Restaurent: "Restaurent",
  //   Tiffin: "Tiffin",
  // },
};
