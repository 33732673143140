import React, { useContext } from "react";
import { useState } from "react";
import "../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

// *******************toster****************************
import ModalSave from "../common/ModelSave";
import { CancelButton, SaveButton } from "../common/Button";

import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import JoditEditor from "jodit-react";
library.add(fas);

const AddOffCanvance = (props) => {
  const { postData, getDimension } = useContext(Context);
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setError,
    getValues,
  } = useForm();

  const imageFile = watch("image");

  const onSubmit = async (data) => {
    try {
      const sendData = new FormData();
      sendData.append("first_name", data?.first_name);
      sendData.append("last_name", data?.last_name);
      sendData.append("email", data?.email);
      sendData.append("contact_no", data?.contact_no);
      sendData.append("company_name", data?.company_name);
      sendData.append("image", data?.image[0]);

      const response = await postData(`/supplier`, sendData);

      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
        setTimeout(() => {
          setShowModal(0);
          props.handleClose();
        }, 2000);
      } else {
        if (response?.message?.email) {
          setError("email", { message: response?.message?.email });
        }
        if (response?.message?.contact_no) {
          setError("contact_no", { message: response?.message?.contact_no });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [charCount, setCharCount] = useState(0);
  const maxCharLimit = 100;

  const handleInputChange = (e) => {
    setCharCount(e.target.value.length);
  };

  return (
    <>
      <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Supplier
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={() => handleSubmit(onSubmit)} role="form">
            <Row className="justify-content-center">
              <Col md={12}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="main-form-section mt-3">
                      <Form.Group>
                        <Form.Label>First Name</Form.Label>
                        <InputGroup>
                          <Form.Control
                            type="text"
                            name="first_name"
                            placeholder="First Name"
                            className={classNames("", {
                              "is-invalid": errors?.first_name,
                            })}
                            {...register("first_name", {
                              required: "First Name is required",
                              maxLength: {
                                value: maxCharLimit,
                                message: `Name cannot exceed ${maxCharLimit} characters`,
                              },
                              pattern: {
                                value: /^[A-Za-z]+$/,
                                message: "Invalid Format",
                              },
                            })}
                            maxLength={maxCharLimit}
                            onChange={handleInputChange}
                          />
                        </InputGroup>{" "}
                        <div className="text-end">
                          <small>{maxCharLimit} characters Limit </small>
                        </div>
                        {errors.first_name && (
                          <span className="text-danger">
                            {errors.first_name.message}
                          </span>
                        )}
                      </Form.Group>
                    </div>
                  </div>

                  <div className="col-md-6">
                    {" "}
                    <div className="main-form-section mt-3">
                      <Form.Group>
                        <Form.Label>Last Name</Form.Label>

                        <InputGroup>
                          <Form.Control
                            type="text"
                            name="last_name"
                            placeholder="Last Name"
                            className={classNames("", {
                              "is-invalid": errors?.last_name,
                            })}
                            {...register("last_name", {
                              required: "Last Name is required",
                              pattern: {
                                value: /^[A-Za-z]+$/,
                                message: "Invalid Format",
                              },
                            })}
                          />
                        </InputGroup>
                        {errors.last_name && (
                          <span className="text-danger">
                            {errors.last_name.message}
                          </span>
                        )}
                      </Form.Group>
                    </div>
                  </div>
                  <div className="col-md-6">
                    {" "}
                    <div className="main-form-section mt-3">
                      <Form.Group>
                        <Form.Label>Email</Form.Label>
                        <InputGroup>
                          <Form.Control
                            type="text"
                            name="email"
                            placeholder="email"
                            className={classNames("", {
                              "is-invalid": errors?.email,
                            })}
                            {...register("email", {
                              required: "Email is required",
                              pattern: {
                                value:
                                  /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
                                message: "Invalid email address",
                              },
                            })}
                          />
                        </InputGroup>
                        {errors.email && (
                          <span className="text-danger">
                            {errors.email.message}
                          </span>
                        )}
                      </Form.Group>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="main-form-section mt-3">
                      <Form.Group>
                        <Form.Label>Contact Number</Form.Label>
                        <InputGroup>
                          <Form.Control
                            className={classNames("", {
                              "is-invalid": errors?.contact_no,
                            })}
                            type="tel" // Changed to 'tel'
                            name="contact_no"
                            placeholder="Enter Contact Number"
                            {...register("contact_no", {
                              required: "Contact Number is required",
                              validate: {
                                maxLength: (value) =>
                                  value.length === 10 ||
                                  "Number should be exactly 10 digits",
                                isNumeric: (value) =>
                                  /^[0-9]*$/.test(value) ||
                                  "Number should be numeric",
                              },
                            })}
                            onInput={(e) => {
                              // Allow only digits, and restrict to 10 digits
                              let value = e.target.value.replace(/[^0-9]/g, ""); // Remove any non-numeric characters
                              if (value.length > 10) {
                                value = value.substring(0, 10); // Limit to 10 digits
                              }
                              e.target.value = value;
                            }}
                            maxLength={10} // Ensures no more than 10 digits can be entered
                          />
                        </InputGroup>
                        {errors.contact_no && (
                          <span className="text-danger">
                            {errors.contact_no.message}
                          </span>
                        )}
                      </Form.Group>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="main-form-section mt-3">
                      <Form.Group>
                        <Form.Label>Company Name</Form.Label>

                        <InputGroup>
                          <Form.Control
                            type="text"
                            name="company_name"
                            placeholder="Company Name"
                            className={classNames("", {
                              "is-invalid": errors?.company_name,
                            })}
                            {...register("company_name", {
                              required: "Company Name is required",
                              // pattern: {
                              //   value: /^[A-Za-z]+$/,
                              //   message: "Invalid Format",
                              // },
                            })}
                          />
                        </InputGroup>
                        {errors.company_name && (
                          <span className="text-danger">
                            {errors.company_name.message}
                          </span>
                        )}
                      </Form.Group>
                    </div>
                  </div>

                  <div className="col-md-6">
                    {" "}
                    <div className="main-form-section mt-3">
                      <Row className="justify-content-start">
                        <Form.Label className="text-left">
                          Profile Image
                        </Form.Label>

                        <Form.Group>
                          <Form.Control
                            className={classNames("", {
                              "is-invalid": errors?.image,
                            })}
                            type="file"
                            {...register("image", {
                              required: "Image is required",
                              // validate: async (value) => {
                              //   if (typeof value !== "string") {
                              //     const fileTypes = ["jpg", "png", "jpeg"];
                              //     const fileType = value[0].name?.split(".")[1];

                              //     if (!fileTypes.includes(fileType)) {
                              //       return `please upload a valid file format. (${fileTypes})`;
                              //     }

                              //     const sizes = await getDimension(value[0]);
                              //     if (
                              //       sizes.width !== 420 &&
                              //       sizes.height !== 520
                              //     ) {
                              //       return "Image width and height must be 420 px and 520 px";
                              //     }

                              //     const fileSize = Math.round(
                              //       value[0].size / 1024
                              //     );
                              //     if (fileSize > 500) {
                              //       return "file size must be lower than 500kb";
                              //     }
                              //   }
                              // },
                            })}
                            accept=".jpg, .jpeg, .png"
                          />
                        </Form.Group>
                        {errors.image && (
                          <span className="text-danger">
                            {errors.image.message}
                          </span>
                        )}
                      </Row>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="main-form-section mt-3">
                      <Form.Label column sm={12} className="text-right">
                        Image Preview
                      </Form.Label>
                      {imageFile && imageFile?.length > 0 && (
                        <div className="image-preview-container">
                          <img
                            // src={URL.createObjectURL(getValues("image")[0])}
                            src={URL?.createObjectURL(imageFile[0])}
                            alt="Preview"
                            className="image-preview"
                            style={{ width: "100px", height: "100px" }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Col>

              {/* <Col className="main-form-section mt-3"> */}

              {/* </Col> */}

              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>

                  <SaveButton
                    name={"save"}
                    handleSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
