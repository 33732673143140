import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import plus from "../../../Components/assets/icons/a1.png";
import colunms from "../../../Components/assets/icons/LINES.png";
import pen from "../../../Components/assets/icons/pen.png";
import eye from "../../../Components/assets/icons/eye.png";
import basket from "../../../Components/assets/icons/basket.png";
import top from "../../../Components/assets/icons/top.png";
import Table from "react-bootstrap/Table";
import { Link, useNavigate, useParams } from "react-router-dom";
import search1 from "../../../Components/assets/icons/search.png";
import Header from "../../Header/Header";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toast, { Toaster } from "react-hot-toast";
import Card from "react-bootstrap/Card";
import Select from "react-select";
import AddOffCanvance from "./Add";
import EditOffCanvance from "./Edit";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import { Context } from "../../../utils/context";
import { FaEye } from "react-icons/fa";
import Commission_modal from "./Commission_modal/Commission_modal";
import Pagination_Holder from "../../common/Pagination_Holder/Pagination_Holder";
import Pagination from "react-bootstrap/Pagination";

library.add(fas);

// ********************************************************************************************************************************************************

const Tables = () => {
  const {
    getData,
    editStatusData,
    deleteData,
    ErrorNotify,
    isAllow,
    IMG_URL,
    Per_Page_Dropdown,
    Select2Data,
    getDownloadDataExcel,
    usertype,
  } = useContext(Context);

  const [onPageChange, setonPageChange] = useState(1);

  const [totalPages, settotalPages] = useState();

  const handlePageChange = (pageNumber) => {
    console.log(pageNumber, "pageNumber");

    setonPageChange(pageNumber);
  };

  const paginationItems = [];
  for (let page = 1; page <= totalPages; page++) {
    paginationItems.push(
      <Pagination.Item
        key={page}
        active={page === onPageChange}
        onClick={() => handlePageChange(page)}
      >
        {page}
      </Pagination.Item>
    );
  }

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(5);
  const [search, setSearch] = useState("");
  const [reset, setReset] = useState();
  const [show, setShowAdd] = useState(false);
  const [show1, setShowEdit] = useState(0);
  const [showCommission, setShowCommissionEdit] = useState(0);
  const [option, setOption] = useState();
  const [searchByUser, setSearchByUser] = useState(null);
  const [searchCategory, setSearchCategory] = useState("");
  const [searchSubCategory, setSearchSubCategory] = useState("");
  const [searchChildSubCategory, setSearchChildSubCategory] = useState("");
  const [shortCommission, setShortCommission] = useState("");

  const { id } = useParams();

  useEffect(() => {
    setSearchByUser(id || null); // Set to null if id is undefined or null
    getDataAll();
  }, [id]);

  useEffect(() => {
    getDataAll();
  }, [searchByUser]);

  const [user, setUser] = useState({});
  const GetUserData = async () => {
    const response = await getData(`/usersingleget`);
    if (response?.success) {
      setUser(response?.data);
    }
  };
  useEffect(() => {
    GetUserData();
  }, []);

  const [changeStatus, setChangeStatus] = useState();

  const getDataAll = async () => {
    const queryString = `/product?page=${onPageChange}&per_page=${perPage}&term=${search}&searchByUser=${id}&searchCategory=${
      searchCategory?.value || ""
    }&searchSubCategory=${
      searchSubCategory?.value || ""
    }&searchChildSubCategory=${
      searchChildSubCategory?.value || ""
    }&shortCommission=${shortCommission?.value || null}`;
    const response = await getData(queryString);
    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);
    setSearch(response?.data?.search_name);
    settotalPages(response?.data?.totalPages);
    setOption(await Per_Page_Dropdown(response?.data?.totalEntries));

    const newData = response?.data?.data;
    if (newData) {
      const newIds = newData.map((d) => d?.id);
      setAllChecked(newIds);
    }
  };
  useEffect(() => {
    getDataAll();
  }, [
    changeStatus,
    perPage,
    reset,
    show,
    show1,
    // search,
    // searchCategory,
    searchSubCategory,
    searchChildSubCategory,
    shortCommission,
    onPageChange,
  ]);

  const ChangeStatus = async (id) => {
    const response = await editStatusData(`/product/${id}`);
    setChangeStatus(response);
  };

  // Delete module.....................................................
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordToDeleteId, setRecordToDeleteId] = useState(null);
  const [recordToDeleteName, setRecordToDeleteName] = useState(null);

  const showDeleteRecord = async (id, name) => {
    setShowDeleteModal(true);
    setRecordToDeleteId(id);
    setRecordToDeleteName(name);
  };

  // Modal function
  const handleClose = () => setShowAdd(false);
  const handleShow = () => setShowAdd(true);

  const handleClose1 = () => setShowEdit(0);
  const handleShow1 = (id) => {
    setShowEdit(id);
  };

  const handleCommissionClose = () => setShowCommissionEdit(false);

  const handleCommissionShow = (id) => {
    setShowCommissionEdit(id);
  };

  const DeleteRecord = async () => {
    setShowDeleteModal(false);
    if (recordToDeleteId) {
      const response = await deleteData(`/product/${recordToDeleteId}`);
      await ErrorNotify(recordToDeleteName);
      setChangeStatus(response);
      setRecordToDeleteId(null);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setRecordToDeleteId(null);
  };

  //toggle/
  const [visible, setVisibel] = useState({
    col0: true,
    col1: true,
    col2: true,
    col3: true,
    col4: true,
    col5: true,
    col6: true,
    col7: true,
    col8: true,
    col9: true,
    col10: true,
    col11: true,
    col12: true,
  });

  //toggle columnns.........
  const toggleColumn = (event, columnName) => {
    event.preventDefault();
    setVisibel((prev) => ({
      ...prev,
      [columnName]: !prev[columnName],
    }));
  };

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [childSubCategories, setChildSubCategories] = useState([]);

  const GetAllCategory = async () => {
    const response = await getData("/allcategories");
    if (response?.success) {
      setCategories(await Select2Data(response?.data, "category_id"));
    }
  };

  const GetAllSubCategory = async (id) => {
    const response = await getData(`/allsubcategories/${id}`);
    if (response?.success) {
      setSubCategories(await Select2Data(response?.data, "sub_category_id"));
    }
  };

  const GetAllChildSubCategory = async (id) => {
    const response = await getData(`/allchildsubcategories/${id}`);
    if (response?.success) {
      setChildSubCategories(
        await Select2Data(response?.data, "child_sub_category_id")
      );
    }
  };

  useEffect(() => {
    GetAllCategory();
  }, []);

  const handleSelectAll = async () => {
    await setSelectAllChecked(allChecked);
  };

  const handleChange = async (e) => {
    const { value, checked } = e.target;

    if (value === "selectAll") {
      if (checked) {
        handleSelectAll();
      } else {
        await setSelectAllChecked([]);
      }
    } else {
      if (checked) {
        await setSelectAllChecked([...selectAllChecked, Number(value)]);
      } else {
        await setSelectAllChecked(
          selectAllChecked.filter((e) => e !== Number(value))
        );
      }
    }
  };

  const [selectAllChecked, setSelectAllChecked] = useState([]);
  const [allChecked, setAllChecked] = useState([]);

  const HandleDownload = async () => {
    if (selectAllChecked.length == 0) {
      alert("Please Select Atleast One Record");
    } else {
      try {
        await getDownloadDataExcel(
          "/product/download",
          selectAllChecked,
          "Product List"
        );
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  return (
    <>
      <div className="main-advancedashboard">
        <Header title={"Products"} link={"/employee/employee_details"} />
        <section className="AdvanceDashboard">
          <div className="col-xxl-12 col-xl-12  ">
            <div className="row MainRowsec">
              <section className="Tabels tab-radio tab-radio mt-3">
                <div className="">
                  {/* container */}
                  <div className="row">
                    {/* {isAllow.includes(88) ? ( */}
                    {/*  {usertype != "Admin" && ( */}
                    <div className="col-xxl-2 col-xl-2 col-lg-4 col-md-6 col-sm-6 col-12">
                      <div className="add ">
                        <Link
                          // to="/employee/employee_details/add"
                          type="button"
                          className="btn btn-add pe-3"
                        >
                          <div onClick={() => handleShow()}>
                            <p className="add-sub-txttt">
                              {" "}
                              <img
                                src={plus}
                                className="plus me-2 ms-0"
                                alt=""
                              />
                              Add Product
                            </p>
                          </div>
                        </Link>
                      </div>
                    </div>
                    {/* )} */}
                    {/* ) : (
                      <></>
                    )} */}
                    <div className="col-xxl-2 col-xl-2 col-lg-4 col-md-6 col-sm-6 col-12">
                      <div className="add">
                        <div className="dropdown">
                          <button
                            className="btn btn-columns dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img
                              src={colunms}
                              className="columns me-2 "
                              alt=""
                            />
                            Column Selection{" "}
                            <img src={top} className="top ms-1" alt="" />{" "}
                          </button>
                          <ul className="dropdown-menu">
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col1")}
                              >
                                Sr. No.
                                {visible?.col1 ? (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col2")}
                              >
                                Name
                                {visible?.col2 ? (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>

                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col4")}
                              >
                                Short Description{" "}
                                {visible?.col4 ? (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>

                            {/* <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col5")}
                      
                              >
                                Total Variants
                                {visible?.col5 ? (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li> */}

                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col6")}
                              >
                                Image
                                {visible?.col6 ? (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>

                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col7")}
                              >
                                Manufacturer
                                {visible?.col7 ? (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>

                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) =>
                                  toggleColumn(event, "col10")
                                }
                              >
                                Varient
                                {visible?.col10 ? (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>

                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) =>
                                  toggleColumn(event, "col11")
                                }
                              >
                                Commission
                                {visible?.col11 ? (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                            {user?.id !== 1 && (
                              <li>
                                <Link
                                  className="dropdown-item"
                                  onClick={(event) =>
                                    toggleColumn(event, "col8")
                                  }
                                >
                                  Status
                                  {visible?.col8 ? (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="ms-4"
                                      icon="fa-solid fa-eye-slash"
                                    />
                                  )}
                                </Link>
                              </li>
                            )}
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col9")}
                              >
                                {user?.id === 1 ? "View " : "Action "}
                                {visible?.col9 ? (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="border-line my-3"></div>
                    <div className="row">
                      <div className=" col-12">
                        <div className="d-flex mb-3 align-items-center">
                          <div className="show me-2">
                            <p className="show m-0">Show</p>
                          </div>
                          <div className="number me-2">
                            <select
                              className="form-select form-select-sm"
                              aria-label=".form-select-sm example"
                              onChange={(e) => setperPage(e.target.value)}
                            >
                              {option?.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="entries me-5">
                            <p className="show m-0">entries</p>
                          </div>
                          <div className="col-xxl-2 col-xl-3  col-lg-4  col-md-6 col-12 ">
                            <div className="sowing ">
                              <p className="show m-0">{`Showing ${Math.min(
                                (currentPage - 1) * perPage + 1
                              )} to ${Math.min(
                                currentPage * perPage,
                                data?.data?.totalEntries
                              )} of ${data?.data?.totalEntries} entries`}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="row">
                          <div className="col-xxl-2 col-xl-3  col-lg-4  col-md-6 col-12 mb-2">
                            <div className="num ">
                              <Select
                                isSearchable
                                options={categories}
                                value={searchCategory}
                                placeholder="Select Category"
                                onChange={(e) => {
                                  setSearchCategory(e);
                                  GetAllSubCategory(e.value);
                                  setSearchSubCategory("");
                                  setSearchChildSubCategory("");
                                  handlePageChange(1);
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-xxl-2 col-xl-3  col-lg-4  col-md-6 col-12 mb-2">
                            <div className="num  text-end">
                              <input
                                type="text"
                                className="form-control"
                                id=""
                                value={search}
                                placeholder="Name"
                                onChange={(e) => {
                                  setSearch(e.target.value);
                                  handlePageChange(1);
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-xxl-1 col-xl-2  col-lg-2  col-md-2 col-12 mb-2">
                            <Button
                              type="button"
                              onClick={getDataAll}
                              className="btn btn-search btn btn-primary w-100"
                            >
                              <img src={search1} className="search" alt="" />
                            </Button>
                          </div>
                          <div className="col-xxl-1 col-xl-2  col-lg-2  col-md-2 col-12 mb-2">
                            <button
                              type="button"
                              onClick={() => {
                                setSearch("");
                                setSearchCategory("");
                                setSearchSubCategory("");
                                setSearchChildSubCategory("");
                                setReset(!reset);
                              }}
                              className="btn btn-reset w-100"
                            >
                              Reset
                            </button>
                          </div>

                          <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 col-12 mb-2">
                            <button
                              className="btn btn-success w-100"
                              onClick={HandleDownload}
                            >
                              Download data in Excel
                              <FontAwesomeIcon
                                icon="fa-solid fa-file-lines"
                                className="ms-2"
                              />
                            </button>
                          </div>

                          {/* <div className="Search me-2">
                            <Button
                              type="button"
                             onClick={getDataAll}
                              className="btn btn-search btn btn-primary w-100"
                            >
                              <img src={search1} className="search" alt="" />
                            </Button>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border-line my-3"></div>
                  <div className="row mt-3">
                    <div className="data table-responsive">
                      <Table striped bordered hover responsive center>
                        <thead>
                          <tr className="">
                            <th>
                              <input
                                type="checkbox"
                                value="selectAll"
                                checked={
                                  allChecked.length === selectAllChecked.length
                                }
                                onChange={handleChange}
                                id="selectAll"
                                className="me-1"
                              ></input>
                              Select
                            </th>
                            {visible.col1 && <th className="sr">Sr. No.</th>}
                            {visible.col2 && (
                              <th
                                style={{ width: "900px" }}
                                className="tax-name"
                              >
                                Name
                              </th>
                            )}
                            {visible.col4 && (
                              <th
                                style={{ width: "900px" }}
                                className="tax-name"
                              >
                                Short Description
                              </th>
                            )}
                            {/* {visible.col5 && (
                              <th
                                style={{ width: "900px" }}
                                className="tax-name"
                              >
                                Total Variants
                              </th>
                            )} */}
                            {visible.col6 && (
                              <th
                                style={{ width: "900px" }}
                                className="tax-name"
                              >
                                Image
                              </th>
                            )}
                            {visible.col7 && (
                              <th
                                style={{ width: "900px" }}
                                className="tax-name"
                              >
                                Manufacturer
                              </th>
                            )}
                            {visible.col10 && (
                              <th
                                style={{ width: "900px" }}
                                className="tax-name"
                              >
                                Varient
                              </th>
                            )}{" "}
                            {visible.col11 && (
                              <th
                                style={{ width: "900px" }}
                                className="tax-name"
                              >
                                Commission Charges
                              </th>
                            )}
                            {visible.col12 && (
                              <th
                                style={{ width: "900px" }}
                                className="tax-name"
                              >
                                Status
                              </th>
                            )}
                            {visible.col9 && (
                              <th
                                style={{ width: "900px" }}
                                className="tax-name"
                              >
                                {user?.id === 1 ? "View " : "Action "}
                              </th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {/* {isAllow.includes(5) ? ( */}
                          {data?.data?.data?.length > 0 ? (
                            data.data.data.map((d, index) => {
                              const paginatedIndex =
                                (onPageChange - 1) * perPage + index + 1;
                              return (
                                <tr className="" key={index}>
                                  <td>
                                    {/* {(d.order_status_id === 1 ||
                                    d.order_status_id === 2 ||
                                    d.order_status_id === 3) && ( */}
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      value={d?.id}
                                      name="perselected"
                                      checked={selectAllChecked.includes(d?.id)}
                                      onChange={handleChange}
                                      id="flexCheckDefault"
                                    />
                                    {/* )} */}
                                  </td>
                                  {visible.col1 && <td>{paginatedIndex}.</td>}{" "}
                                  {visible.col2 && <td>{d?.name}</td>}
                                  {visible.col4 && (
                                    <td>{d?.short_description}</td>
                                  )}
                                  {/* {visible.col5 && (
                                  <td>{d?.product_variants.length}</td>
                                )} */}
                                  {visible.col6 && (
                                    <td>
                                      {d?.image1 && (
                                        <img
                                          src={IMG_URL + d?.image1}
                                          alt="Image"
                                          width="100"
                                          height="100"
                                        />
                                      )}
                                    </td>
                                  )}
                                  {visible.col7 && (
                                    <td>{d?.product_detail?.manufacturer}</td>
                                  )}{" "}
                                  {visible.col10 && <td>{d?.type}</td>}
                                  {/* {visible.col6 && <td>{d?.password}</td>} */}
                                  {/* {visible.col6 && <td>******</td>} */}
                                  {/* {visible.col8 && user?.id !== 1 && (
                                  <td>
                                    {d?.id !== 1 &&
                                      (d?.stage === "Approved" ? (
                                        <div className="form-check form-switch">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            role="switch"
                                            checked={d.status}
                                            disabled={!isAllow?.includes(89)}
                                            onChange={() => {
                                              ChangeStatus(d.id);
                                            }}
                                            id={`flexSwitchCheckDefault${d?.id}`}
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor={`flexSwitchCheckDefault${d?.id}`}
                                          >
                                            {d.status ? "Active" : "Inactive"}
                                          </label>
                                        </div>
                                      ) : (
                                        <p>{d?.stage}</p>
                                      ))}
                                  </td>
                                )} */}
                                  {visible.col11 && (
                                    <td>
                                      <div className="d-flex justify-content-between align-items-center">
                                        <span>{d?.commission}%</span>
                                        <Button
                                          onClick={() =>
                                            handleCommissionShow(d?.id)
                                          }
                                          type="button"
                                          className="btn btn-primary"
                                          disabled={usertype == "Seller"}
                                        >
                                          <img
                                            src={pen}
                                            className="pen"
                                            alt=""
                                          />
                                        </Button>
                                      </div>
                                    </td>
                                  )}
                                  {visible.col12 && (
                                    <td>
                                      <div className="form-check form-switch">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          role="switch"
                                          checked={d.status}
                                          disabled={!isAllow?.includes(241)}
                                          onChange={() => {
                                            ChangeStatus(d.id);
                                          }}
                                          id={`flexSwitchCheckDefault${d?.id}`}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={`flexSwitchCheckDefault${d?.id}`}
                                        >
                                          {d.status ? "Active" : "Inactive"}
                                        </label>
                                      </div>
                                    </td>
                                  )}
                                  {visible.col9 && (
                                    <td>
                                      <div className="d-flex">
                                        {d.stage == "Approved" ? (
                                          <Button
                                            onClick={() => {
                                              handleShow1(d?.id);
                                            }}
                                            type="button"
                                            className="btn btn-primary me-1"
                                          >
                                            {user?.id === 1 ? (
                                              <img
                                                src={eye}
                                                className="pen"
                                                alt=""
                                              />
                                            ) : (
                                              <img
                                                src={pen}
                                                className="pen"
                                                alt=""
                                              />
                                            )}
                                          </Button>
                                        ) : (
                                          <Button
                                            onClick={() => {
                                              handleShow1(d?.id);
                                            }}
                                            type="button"
                                            className="btn btn-primary me-1"
                                          >
                                            {user?.id === 1 ? (
                                              <img
                                                src={eye}
                                                className="pen"
                                                alt=""
                                              />
                                            ) : (
                                              <img
                                                src={pen}
                                                className="pen"
                                                alt=""
                                              />
                                            )}
                                          </Button>
                                        )}
                                        {/* <Button
                                        onClick={() => {
                                          if (d.stage === "Approved") {
                                            handleShow1(d?.id);
                                          }
                                        }}
                                        type="button"
                                        className="btn btn-primary me-1"
                                      >
                                        {user?.id === 1 ? (
                                          <img
                                            src={eye}
                                            className="pen"
                                            alt=""
                                          />
                                        ) : (
                                          <img
                                            src={pen}
                                            className="pen"
                                            alt=""
                                          />
                                        )}
                                      </Button> */}

                                        {/* {isAllow.includes(90) ? ( */}
                                        <button
                                          onClick={() => {
                                            showDeleteRecord(d?.id, d?.name);
                                          }}
                                          type="button"
                                          className="btn btn-danger"
                                        >
                                          <img
                                            src={basket}
                                            className="pen"
                                            alt=""
                                          />
                                        </button>
                                        {/* ) : (
                                        <></>
                                      )} */}
                                      </div>
                                      {/* )} */}
                                    </td>
                                  )}
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="10" className="text-center">
                                Data not available
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                      <Pagination_Holder
                        onPageChange={currentPage}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                      />
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>

      {show ? (
        <AddOffCanvance
          handleClose={handleClose}
          setShow={setShowAdd}
          show={show}
        />
      ) : (
        ""
      )}

      {show1 ? (
        <EditOffCanvance
          handleClose={handleClose1}
          setShow={setShowEdit}
          show={show1}
        />
      ) : (
        ""
      )}

      {showCommission ? (
        <Commission_modal
          handleClose={handleCommissionClose}
          setShow={setShowCommissionEdit}
          show={showCommission}
        />
      ) : (
        ""
      )}

      <Toaster position="top-right" />

      {/* <!-- Modal Delete --> */}
      <div className="upload-modal">
        <div
          className={`modal fade ${showDeleteModal ? "show" : ""}`}
          style={{ display: showDeleteModal ? "block" : "none" }}
          id="exampleModaldel"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <h1 className="add-success text-center  mt-2">
                  Are you sure ?
                </h1>
                <p>
                  Do You Really Want to Delete These Record?
                  <br /> This Process CanNot Be Undone{" "}
                </p>
                <div className="button-holder text-center mt-2">
                  <button className="btn btn-yes me-2" onClick={DeleteRecord}>
                    Yes
                  </button>
                  <button className="btn btn-no" onClick={handleDeleteCancel}>
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tables;
