import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
// *******************toster****************************
import ModalSave from "../common/ModelSave";
import { CancelButton, SaveButton } from "../common/Button";
import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import classNames from "classnames";
library.add(fas);

const EditOffCanvance = (props) => {
  const id = props.show;
  const { postData, getData } = useContext(Context);
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const [data, setData] = useState({});

  const GetEditData = async () => {
    const response = await getData(`/employee/seller/${id}`);
    reset(response?.data);
    setData(response?.data);
    setCommission(response?.data?.commission);
  };
  useEffect(() => {
    GetEditData();
  }, []);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
    getValues,
  } = useForm();

  const [commission, setCommission] = useState();

  const ZeroCommission = commission == undefined ? 0 : commission;

  const handleCommissionChange = (e) => {
    const value = e.target.value;
    setCommission(value);

    // Update the commission value in the form
    setValue("commission", value);
  };

  const onSubmit = async (data) => {
    try {
      const d = new FormData();
      // d.append("commission", data?.commission);
      // if (data?.price) {
      //   d.append("price", data?.price);
      // }
      d.append("commission_s_date", data?.commission_s_date);
      d.append("commission_e_date", data?.commission_e_date);
      const response = await postData(`/employee/seller/commission/${id}`, d);

      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({
          code: response?.code,
          message: response?.message,
        });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit {data?.first_name}'s Service
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={() => handleSubmit(onSubmit)} role="form">
            <Row className="justify-content-center">
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    {/* <Form.Group>
                      <div className="text-center">
                        <Form.Label>Commission</Form.Label>
                      </div>
                      <InputGroup>
                        <Form.Control
                          type="text" // Keep as text type
                          name="commission"
                          placeholder="Commission"
                          className={classNames("", {
                            "is-invalid": errors?.commission,
                          })}
                          {...register("commission", {
                            required: "Commission is required",
                            validate: (value) => {
                              if (value === "") return "Commission is required";
                              if (value < 0)
                                return "Commission cannot be negative";
                              if (value > 99)
                                return "Commission cannot exceed 99"; // Validation for maximum value
                              return true; // Valid input
                            },
                          })}
                          value={commission}
                          onInput={(e) => {
                            // Allow only digits and one decimal point, and restrict to 99
                            e.target.value = e.target.value
                              .replace(/[^0-9.]/g, "") // Remove any non-numeric or non-decimal characters
                              .replace(/(\..*)\./g, "$1"); // Allow only one decimal point

                            // Restrict input to values less than or equal to 99
                            if (parseFloat(e.target.value) > 99) {
                              e.target.value = "99";
                            }
                          }}
                          onChange={handleCommissionChange}
                        />
                      </InputGroup>
                      {errors.commission && (
                        <span className="text-danger">
                          {errors.commission.message}
                        </span>
                      )}
                    </Form.Group> */}

                    {/* {commission == 0 && (
                      <Form.Group>
                        <div className="text-center">
                          <Form.Label>Price</Form.Label>
                        </div>
                        <InputGroup>
                          <Form.Control
                            type="text"
                            min={0} // Prevents negative input from UI
                            name="price"
                            placeholder="Price"
                            className={classNames("", {
                              "is-invalid": errors?.price,
                            })}
                            {...register("price", {
                              required: "Price is required",
                              validate: (value) => {
                                if (value === "") return "Price is required";
                                if (value < 0)
                                  return "Price cannot be negative"; // Validate negative values
                                if (value == 0) return "Price cannot be 0"; // Validate for 0

                                return true; // Valid input
                              },
                            })}
                            onInput={(e) => {
                              // Allow only digits and one decimal point, and restrict to 99
                              e.target.value = e.target.value
                                .replace(/[^0-9.]/g, "") // Remove any non-numeric or non-decimal characters
                                .replace(/(\..*)\./g, "$1"); // Allow only one decimal point

                              // Restrict input to values less than or equal to 99
                            }}
                          />
                        </InputGroup>
                        {errors.price && (
                          <span className="text-danger">
                            {errors.price.message}
                          </span>
                        )}
                      </Form.Group>
                    )} */}

                    <Form.Group>
                      <div className="text-center">
                        <Form.Label>Service Start Date</Form.Label>
                      </div>
                      <InputGroup>
                        <Form.Control
                          type="date"
                          name="commission_s_date"
                          placeholder="Commission Start Date"
                          className={classNames("", {
                            "is-invalid": errors?.commission_s_date,
                          })}
                          {...register("commission_s_date", {
                            required: "Commission Start Date is required",
                            validate: (value) => {
                              if (!value)
                                return "Commission Start Date is required";

                              const selectedDate = new Date(value);
                              const today = new Date();

                              // Check if the selected date is less than today
                              if (selectedDate < today.setHours(0, 0, 0, 0)) {
                                return "Commission Start Date cannot be in the past";
                              }

                              return true; // Return true for valid input
                            },
                          })}
                        />
                      </InputGroup>
                      {errors.commission_s_date && (
                        <span className="text-danger">
                          {errors.commission_s_date.message}
                        </span>
                      )}
                    </Form.Group>

                    <Form.Group>
                      <div className="text-center">
                        <Form.Label>Service End Date</Form.Label>
                      </div>
                      <InputGroup>
                        <Form.Control
                          type="date"
                          name="commission_e_date"
                          placeholder="Commission End Date"
                          className={classNames("", {
                            "is-invalid": errors?.commission_e_date,
                          })}
                          {...register("commission_e_date", {
                            required: "Commission End Date is required",
                            validate: (value) => {
                              if (!value)
                                return "Commission End Date is required";

                              const startDate = new Date(
                                watch("commission_s_date")
                              );
                              const endDate = new Date(value);

                              if (endDate < startDate) {
                                return "Commission End Date cannot be before the Commission Start Date";
                              }

                              return true; // Return true for valid input
                            },
                          })}
                        />
                      </InputGroup>
                      {errors.commission_e_date && (
                        <span className="text-danger">
                          {errors.commission_e_date.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>

                  <SaveButton
                    name={"save"}
                    handleSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
