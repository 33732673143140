import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
// *******************toster****************************
import ModalSave from "../../common/ModelSave";
import { CancelButton, SaveButton } from "../../common/Button";
import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import ColorPickerInput from "../../common/ColorPicker";
import JoditEditor from "jodit-react";
library.add(fas);

const EditOffCanvance = (props) => {
  const id = props.show;
  const { postData, getData, IMG_URL, getDimension } = useContext(Context);
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });
  const [headerColor, setHeaderColor] = useState("");
  const [headerFontColor, setHeaderFontColor] = useState("");
  const [footerColor, setFooterColor] = useState("");
  const [footerFontColor, setFooterFontColor] = useState("");

  const GetEditData = async () => {
    const response = await getData(`/home/app-setup/${id}`);
    reset(response?.data);
    setHeaderColor(response?.data?.header_color);
    setHeaderFontColor(response?.data?.header_font_color);
    setFooterColor(response?.data?.footer_color);
    setFooterFontColor(response?.data?.footer_font_color);
  };
  useEffect(() => {
    GetEditData();
  }, []);

  const {
    control,
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
    reset,
  } = useForm();

  const imageFile = watch("image");
  const scannerFile = watch("scanner");

  const onSubmit = async (data) => {
    try {
      const finalData = new FormData();
      finalData.append("name", data?.name);
      finalData.append("amount_per_km", data?.amount_per_km);
      finalData.append("contact_no", data?.contact_no);
      finalData.append("seller_help_desk_no", data?.seller_help_desk_no);
      finalData.append("return_exchange", data?.return_exchange);
      finalData.append("complain_assistance", data?.complain_assistance);
      finalData.append("address", data?.address);
      finalData.append("address_two", data?.address_two);
      finalData.append("email", data?.email);
      // finalData.append("header_color", headerColor);
      // finalData.append("header_font_color", headerFontColor);
      // finalData.append("footer_color", footerColor);
      // finalData.append("footer_font_color", footerFontColor);
      finalData.append("image", data?.image[0]);
      finalData.append("scanner", data?.scanner[0]);
      const response = await postData(`/home/app-setup/${id}`, finalData);

      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const [charCount, setCharCount] = useState(0);
  const maxCharLimit = 100;

  const handleInputChange = (e) => {
    setCharCount(e.target.value.length);
  };

  return (
    <>
      <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit App Setup
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={() => handleSubmit(onSubmit)} role="form">
            <Row>
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Name</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder="Banner"
                          className={classNames("", {
                            "is-invalid": errors?.name,
                          })}
                          {...register("name", {
                            required: "Name is required",
                            maxLength: {
                              value: maxCharLimit,
                              message: `Name cannot exceed ${maxCharLimit} characters`,
                            },
                          })}
                          maxLength={maxCharLimit}
                          onChange={handleInputChange}
                        />
                      </InputGroup>{" "}
                      <div className="text-end">
                        <small>{maxCharLimit} characters Limit </small>
                      </div>
                      {errors.name && (
                        <span className="text-danger">
                          {errors.name.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Group>
                      <Form.Label>Contact No.</Form.Label>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="contact_no"
                          placeholder="Contact No"
                          className={classNames("", {
                            "is-invalid": errors?.contact_no,
                          })}
                          {...register("contact_no", {
                            required: "Contact No is required",
                            minLength: {
                              value: 10,
                              message: "Contact No must be at least 10 digits",
                            },
                            maxLength: {
                              value: 10,
                              message: "Contact No cannot exceed 10 digits",
                            },
                          })}
                          onInput={(e) => {
                            // Allow only digits, and restrict to 10 digits
                            let value = e.target.value.replace(/[^0-9]/g, ""); // Remove any non-numeric characters
                            if (value.length > 10) {
                              value = value.substring(0, 10); // Limit to 10 digits
                            }
                            e.target.value = value;
                          }}
                        />
                      </InputGroup>
                      {errors.contact_no && (
                        <span className="text-danger">
                          {errors.contact_no.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Return Exchange Number</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="return_exchange"
                          placeholder="Return Exchange No"
                          className={classNames("", {
                            "is-invalid": errors?.return_exchange,
                          })}
                          {...register("return_exchange", {
                            required: "Return Exchange No is required",
                            minLength: {
                              value: 10,
                              message: "Contact No must be at least 10 digits",
                            },
                            maxLength: {
                              value: 10,
                              message: "Contact No cannot exceed 10 digits",
                            },
                          })}
                          onInput={(e) => {
                            // Allow only digits, and restrict to 10 digits
                            let value = e.target.value.replace(/[^0-9]/g, ""); // Remove any non-numeric characters
                            if (value.length > 10) {
                              value = value.substring(0, 10); // Limit to 10 digits
                            }
                            e.target.value = value;
                          }}
                        />
                      </InputGroup>
                      {errors.return_exchange && (
                        <span className="text-danger">
                          {errors.return_exchange.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Complaint Assistance Number</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="complain_assistance"
                          placeholder="Complaint Assistance No"
                          className={classNames("", {
                            "is-invalid": errors?.complain_assistance,
                          })}
                          {...register("complain_assistance", {
                            required: "Complaint Assistance No is required",
                            minLength: {
                              value: 10,
                              message: "Contact No must be at least 10 digits",
                            },
                            maxLength: {
                              value: 10,
                              message: "Contact No cannot exceed 10 digits",
                            },
                          })}
                          onInput={(e) => {
                            // Allow only digits, and restrict to 10 digits
                            let value = e.target.value.replace(/[^0-9]/g, ""); // Remove any non-numeric characters
                            if (value.length > 10) {
                              value = value.substring(0, 10); // Limit to 10 digits
                            }
                            e.target.value = value;
                          }}
                        />
                      </InputGroup>
                      {errors.complain_assistance && (
                        <span className="text-danger">
                          {errors.complain_assistance.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Seller Help Desk Number</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="seller_help_desk_no"
                          placeholder="Seller Help Desk No"
                          className={classNames("", {
                            "is-invalid": errors?.seller_help_desk_no,
                          })}
                          {...register("seller_help_desk_no", {
                            required: "Help Desk Number is required",
                            minLength: {
                              value: 10,
                              message: "Contact No must be exactly 10 digits",
                            },
                            maxLength: {
                              value: 10,
                              message: "Contact No must be exactly 10 digits",
                            },
                            pattern: {
                              value: /^[0-9]{10}$/, // Ensures only 10 digits are allowed
                              message:
                                "Help Desk Number must be exactly 10 digits",
                            },
                          })}
                          onInput={(e) => {
                            // Allow only digits, and restrict to 10 digits
                            let value = e.target.value.replace(/[^0-9]/g, ""); // Remove any non-numeric characters
                            if (value.length > 10) {
                              value = value.substring(0, 10); // Limit to 10 digits
                            }
                            e.target.value = value;
                          }}
                        />
                      </InputGroup>
                      {errors.seller_help_desk_no && (
                        <span className="text-danger">
                          {errors.seller_help_desk_no.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col lg={12}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-start">
                    {" "}
                    {/* Align to left */}
                    {/* Description */}
                    <Col sm={12}>
                      <Form.Label className="text-center">Address</Form.Label>
                      <Form.Group>
                        <Controller
                          name="address" // Provide the field name
                          control={control} // Pass the control object from useForm()
                          rules={{ required: "Address is required" }} // Validation rules
                          render={({ field }) => (
                            <JoditEditor
                              value={field?.value}
                              onChange={(newContent) =>
                                field.onChange(newContent)
                              }
                              onBlur={field.onBlur}
                              className={classNames("", {
                                "is-invalid": !!errors.address,
                              })}
                              placeholder="address"
                            />
                          )}
                        />
                      </Form.Group>
                      {errors.address && (
                        <span className="text-danger">
                          {errors.address.message}
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col lg={12}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-start">
                    {" "}
                    {/* Align to left */}
                    {/* Description */}
                    <Col sm={12}>
                      <Form.Label className="text-center">
                        Address Two
                      </Form.Label>
                      <Form.Group>
                        <Controller
                          name="address_two" // Provide the field name
                          control={control} // Pass the control object from useForm()
                          rules={{ required: "Address Two is required" }} // Validation rules
                          render={({ field }) => (
                            <JoditEditor
                              value={field?.value}
                              onChange={(newContent) =>
                                field.onChange(newContent)
                              }
                              onBlur={field.onBlur}
                              className={classNames("", {
                                "is-invalid": !!errors.address_two,
                              })}
                              placeholder="address_two"
                            />
                          )}
                        />
                      </Form.Group>
                      {errors.address_two && (
                        <span className="text-danger">
                          {errors.address_two.message}
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Email</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="email"
                          placeholder="Email"
                          className={classNames("", {
                            "is-invalid": errors?.email,
                          })}
                          {...register("email", {
                            required: "Email is required",
                          })}
                        />
                      </InputGroup>
                      {errors.email && (
                        <span className="text-danger">
                          {errors.email.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              {/* <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Header Color</Form.Label>
                    <Form.Group>
                      <ColorPickerInput
                        value={headerColor}
                        onChange={(color) => setHeaderColor(color)}
                        errors={errors} // Pass errors to the component if needed
                      />
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Header Font Color</Form.Label>
                    <Form.Group>
                      <ColorPickerInput
                        value={headerFontColor}
                        onChange={(color) => setHeaderFontColor(color)}
                        errors={errors}
                      />
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Footer Color</Form.Label>
                    <Form.Group>
                      <ColorPickerInput
                        value={footerColor}
                        onChange={(color) => setFooterColor(color)}
                        errors={errors} // Pass errors to the component if needed
                      />
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Footer Font Color</Form.Label>
                    <Form.Group>
                      <ColorPickerInput
                        value={footerFontColor}
                        onChange={(color) => setFooterFontColor(color)}
                        errors={errors} // Pass errors to the component if needed
                      />
                    </Form.Group>
                  </Row>
                </div>
              </Col> */}

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Amount Per Km</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="amount_per_km"
                          placeholder="Amount Per Km"
                          className={classNames("", {
                            "is-invalid": errors?.amount_per_km,
                          })}
                          {...register("amount_per_km", {
                            required: "Amount Per km is required",
                          })}
                          onInput={(e) => {
                            // Allow only digits and one decimal point, and restrict to 99
                            e.target.value = e.target.value
                              .replace(/[^0-9.]/g, "") // Remove any non-numeric or non-decimal characters
                              .replace(/(\..*)\./g, "$1"); // Allow only one decimal point

                            // Restrict input to values less than or equal to 99
                          }}
                        />
                      </InputGroup>
                      {errors.amount_per_km && (
                        <span className="text-danger">
                          {errors.amount_per_km.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-start">
                    <Form.Label className="text-left">Image</Form.Label>

                    <Form.Group>
                      <Form.Control
                        className={classNames("", {
                          "is-invalid": errors?.image,
                        })}
                        type="file"
                        {...register("image", {
                          // required: "Image is required",
                          // validate: async (value) => {
                          //   if (typeof value !== "string") {
                          //     const fileTypes = ["jpg", "png", "jpeg"];
                          //     const fileType = value[0].name?.split(".")[1];
                          //     if (!fileTypes.includes(fileType)) {
                          //       return `please upload a valid file format. (${fileTypes})`;
                          //     }
                          //     const sizes = await getDimension(value[0]);
                          //     if (
                          //       sizes.width !== 1296 &&
                          //       sizes.height !== 465
                          //     ) {
                          //       return "Image width and height must be 1296 px and 465 px";
                          //     }
                          //   }
                          // },
                        })}
                        accept=".jpg, .jpeg, .png"
                      />
                    </Form.Group>
                    {errors.image && (
                      <span className="text-danger">
                        {errors.image.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

              <Col lg={12} className="main-form-section mt-3">
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Image Preview</Form.Label>

                    {typeof getValues("image") == "string" ? (
                      <div
                        className="image-preview-container mt-3"
                        
                      >
                        <img
                          src={IMG_URL + getValues("image")}
                          alt="Preview"
                          className="image-preview"
                          style={{ width: "100%", height: "100%" }}
                        />
                      </div>
                    ) : (
                      imageFile &&
                      imageFile?.length > 0 && (
                        <div
                          className="image-preview-container mt-3"
                        >
                          <img
                            // src={URL.createObjectURL(getValues("image")[0])}
                            src={URL?.createObjectURL(imageFile[0])}
                            alt="Preview"
                            className="image-preview"
                            style={{ width: "100%", height: "100%" }}
                          />
                        </div>
                      )
                    )}
                  </Row>
                </div>
              </Col>

              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-start">
                    <Form.Label className="text-left">QR Code</Form.Label>

                    <Form.Group>
                      <Form.Control
                        className={classNames("", {
                          "is-invalid": errors?.scanner,
                        })}
                        type="file"
                        {...register("scanner", {
                          // required: "Image is required",
                          // validate: async (value) => {
                          //   if (typeof value !== "string") {
                          //     const fileTypes = ["jpg", "png", "jpeg"];
                          //     const fileType = value[0].name?.split(".")[1];
                          //     if (!fileTypes.includes(fileType)) {
                          //       return `please upload a valid file format. (${fileTypes})`;
                          //     }
                          //     const sizes = await getDimension(value[0]);
                          //     if (
                          //       sizes.width !== 1296 &&
                          //       sizes.height !== 465
                          //     ) {
                          //       return "Image width and height must be 1296 px and 465 px";
                          //     }
                          //   }
                          // },
                        })}
                        accept=".jpg, .jpeg, .png"
                      />
                    </Form.Group>
                    {errors.scanner && (
                      <span className="text-danger">
                        {errors.scanner.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

              <Col lg={12} className="main-form-section mt-3">
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>QR Preview</Form.Label>

                    {typeof getValues("scanner") == "string" ? (
                      <div
                        className="image-preview-container mt-3"
                      >
                        <img
                          src={IMG_URL + getValues("scanner")}
                          alt="Preview"
                          className="image-preview"
                          style={{ width: "100%", height: "50%" }}
                        />
                      </div>
                    ) : (
                      scannerFile &&
                      scannerFile?.length > 0 && (
                        <div
                          className="image-preview-container mt-3"
                        >
                          <img
                            // src={URL.createObjectURL(getValues("image")[0])}
                            src={URL?.createObjectURL(scannerFile[0])}
                            alt="Preview"
                            className="image-preview"
                            style={{ width: "100%", height: "100%" }}
                          />
                        </div>
                      )
                    )}
                  </Row>
                </div>
              </Col>

              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>

                  <SaveButton
                    name={"save"}
                    handleSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
