import React, { useContext, useState, useEffect } from "react";
import "../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import ModalSave from "../common/ModelSave";
import { CancelButton, SaveButton } from "../common/Button";
import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import JoditEditor from "jodit-react";
library.add(fas);

const EditOffCanvance = (props) => {
  const id = props.show;
  const { postData, getData, IMG_URL } = useContext(Context);
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    getValues,
  } = useForm({
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  const [previousFiles, setPreviousFiles] = useState({
    aadhar_card: "",
    pan_card: "",
    driving_licence: "",
  });

  console.log(previousFiles, "previousFiles");

  const imageFile = watch("image");
  const password = watch("password", "");
  const aadharFile = watch("aadhar_card");
  const panFile = watch("pan_card");
  const drivingLicenceFile = watch("driving_licence");

  console.log(drivingLicenceFile, "drivingLicenceFile");

  const [isAadharChanged, setIsAadharChanged] = useState(false);

  console.log(isAadharChanged, "isAadharChanged");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsAadharChanged(true);
    } else {
      setIsAadharChanged(false);
    }
  };

  const [isPanChanged, setIsPanChanged] = useState(false);

  const handlePanFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsPanChanged(true);
    } else {
      setIsPanChanged(false);
    }
  };

  const [isDLicenceChanged, setIsDLicenceChanged] = useState(false);

  const handleDLicenceFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsDLicenceChanged(true);
    } else {
      setIsDLicenceChanged(false);
    }
  };

  const onSubmit = async (data) => {
    try {
      const sendData = new FormData();
      sendData.append("first_name", data?.first_name);
      sendData.append("last_name", data?.last_name);
      sendData.append("email", data?.email);
      sendData.append("contact_no", data?.contact_no);

      sendData.append("isAadharChanged", isAadharChanged);
      sendData.append("isDLicenceChanged", isDLicenceChanged);
      sendData.append("isPanChanged", isPanChanged);

      if (data?.password) {
        sendData.append("password", data?.password);
      }

      // Append files if new ones are uploaded, otherwise append previous URLs
      sendData.append(
        "aadhar_card",
        isAadharChanged ? aadharFile[0] : previousFiles.aadhar_card
      );
      sendData.append(
        "pan_card",
        isPanChanged ? panFile[0] : previousFiles.pan_card
      );
      sendData.append(
        "driving_licence",
        isDLicenceChanged
          ? drivingLicenceFile[0]
          : previousFiles.driving_licence
      );

      sendData.append("bank_name", data?.bank_name);
      sendData.append("ifsc_code", data?.ifsc_code);
      sendData.append("account_number", data?.account_number);

      sendData.append("image", data?.image[0]);
      const response = await postData(`/delivery-boy/${id}`, sendData);

      if (response?.success) {
        setShowModal({ code: response.code, message: response.message });
      } else {
        setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const GetEditData = async () => {
    const response = await getData(`/delivery-boy/${id}`);

    console.log(response?.data, "response");

    if (response?.data) {
      setPreviousFiles({
        aadhar_card: response.data?.aadhar_card,
        pan_card: response.data?.pan_card,
        driving_licence: response.data?.driving_licence,
      });
      reset({
        ...response.data,
        password: "",
        confirmPassword: "",
      });
    }
  };

  useEffect(() => {
    if (id) {
      GetEditData();
    }
  }, [id]);

  const [charCount, setCharCount] = useState(0);
  const maxCharLimit = 100;

  const handleInputChange = (e) => {
    setCharCount(e.target.value.length);
  };

  return (
    <>
      <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Delivery Boy
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={() => handleSubmit(onSubmit)} role="form">
            <Row className="justify-content-center">
              <Col md={12}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="main-form-section mt-3">
                      <Form.Group>
                        <Form.Label>First Name</Form.Label>
                        <InputGroup>
                          <Form.Control
                            type="text"
                            name="first_name"
                            placeholder="First Name"
                            className={classNames("", {
                              "is-invalid": errors?.first_name,
                              // "is-valid": getValues("first_name"),
                            })}
                            {...register("first_name", {
                              required: "First Name is required",
                              maxLength: {
                                value: maxCharLimit,
                                message: `Name cannot exceed ${maxCharLimit} characters`,
                              },
                              pattern: {
                                value: /^[A-Za-z]+$/,
                                message: "Invalid Format",
                              },
                            })}
                            maxLength={maxCharLimit}
                            onChange={handleInputChange}
                          />
                        </InputGroup>{" "}
                        <div className="text-end">
                          <small>{maxCharLimit} characters Limit </small>
                        </div>
                        {errors.first_name && (
                          <span className="text-danger">
                            {errors.first_name.message}
                          </span>
                        )}
                      </Form.Group>
                    </div>
                  </div>

                  <div className="col-md-6">
                    {" "}
                    <div className="main-form-section mt-3">
                      <Form.Group>
                        <Form.Label>Last Name</Form.Label>

                        <InputGroup>
                          <Form.Control
                            type="text"
                            name="last_name"
                            placeholder="Last Name"
                            className={classNames("", {
                              "is-invalid": errors?.last_name,
                              // "is-valid": getValues("last_name"),
                            })}
                            {...register("last_name", {
                              required: "Last Name is required",
                              pattern: {
                                value: /^[A-Za-z]+$/,
                                message: "Invalid Format",
                              },
                            })}
                          />
                        </InputGroup>
                        {errors.last_name && (
                          <span className="text-danger">
                            {errors.last_name.message}
                          </span>
                        )}
                      </Form.Group>
                    </div>
                  </div>

                  <div className="col-md-6">
                    {" "}
                    <div className="main-form-section mt-3">
                      <Form.Group>
                        <Form.Label>Email</Form.Label>
                        <InputGroup>
                          <Form.Control
                            type="text"
                            name="email"
                            placeholder="email"
                            className={classNames("", {
                              "is-invalid": errors?.email,
                              // "is-valid": getValues("email"),
                            })}
                            {...register("email", {
                              required: "Email is required",
                              pattern: {
                                value:
                                  /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
                                message: "Invalid email address",
                              },
                            })}
                          />
                        </InputGroup>
                        {errors.email && (
                          <span className="text-danger">
                            {errors.email.message}
                          </span>
                        )}
                      </Form.Group>
                    </div>
                  </div>

                  <div className="col-md-6">
                    {" "}
                    <div className="main-form-section mt-3">
                      <Form.Group>
                        <Form.Label>Contact Number</Form.Label>
                        <InputGroup>
                          <Form.Control
                            className={classNames("", {
                              "is-invalid": errors?.contact_no,
                              // "is-valid": getValues("contact_no"),
                            })}
                            type="text"
                            name="contact_no"
                            placeholder="Enter Contact Number"
                            {...register("contact_no", {
                              required: "Contact Number is required",
                              minLength: {
                                value: 10,
                                message:
                                  "Number should be at least 10 characters",
                              },
                            })}
                            maxLength={10}
                          />
                        </InputGroup>
                        {errors.contact_no && (
                          <span className="text-danger">
                            {errors.contact_no.message}
                          </span>
                        )}
                      </Form.Group>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="main-form-section mt-3">
                      <Form.Group>
                        <Form.Label>Bank Name</Form.Label>
                        <InputGroup>
                          <Form.Control
                            className={classNames("", {
                              "is-invalid": errors?.bank_name,
                            })}
                            type="text"
                            name="bank_name"
                            placeholder="Enter Bank Name"
                            {...register("bank_name", {
                              required: "Bank Name is required",
                            })}
                          />
                        </InputGroup>
                        {errors.bank_name && (
                          <span className="text-danger">
                            {errors.bank_name.message}
                          </span>
                        )}
                      </Form.Group>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="main-form-section mt-3">
                      <Form.Group>
                        <Form.Label>Account Number</Form.Label>
                        <InputGroup>
                          <Form.Control
                            className={classNames("", {
                              "is-invalid": errors?.account_number,
                            })}
                            type="text"
                            name="account_number"
                            placeholder="Enter Account Number"
                            {...register("account_number", {
                              required: "Account Number is required",

                              pattern: {
                                value: /^[0-9]+$/,
                                message: "Only digits are allowed",
                              },
                            })}
                            onInput={(e) => {
                              // Allow only digits, and restrict to 10 digits
                              let value = e.target.value.replace(/[^0-9]/g, ""); // Remove any non-numeric characters
                              // if (value.length > 10) {
                              //   value = value.substring(0, 10); // Limit to 10 digits
                              // }
                              e.target.value = value;
                            }}
                          />
                        </InputGroup>
                        {errors.account_number && (
                          <span className="text-danger">
                            {errors.account_number.message}
                          </span>
                        )}
                      </Form.Group>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="main-form-section mt-3">
                      <Form.Group>
                        <Form.Label>IFSC Code</Form.Label>
                        <InputGroup>
                          <Form.Control
                            className={classNames("", {
                              "is-invalid": errors?.ifsc_code,
                            })}
                            type="text"
                            name="ifsc_code"
                            placeholder="Enter IFSC Code"
                            {...register("ifsc_code", {
                              required: "IFSC Code is required",
                            })}
                          />
                        </InputGroup>
                        {errors.ifsc_code && (
                          <span className="text-danger">
                            {errors.ifsc_code.message}
                          </span>
                        )}
                      </Form.Group>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="main-form-section mt-3">
                      <Form.Group>
                        <Form.Label>Password</Form.Label>
                        <InputGroup>
                          <Form.Control
                            type="text"
                            name="password"
                            placeholder="Password"
                            className={classNames("", {
                              "is-invalid": errors?.password,
                            })}
                            {...register("password", {
                              required: "Password is required",
                              minLength: {
                                value: 8,
                                message:
                                  "Password must be at least 8 characters long",
                              },
                            })}
                          />
                        </InputGroup>
                        {errors.password && (
                          <span className="text-danger">
                            {errors.password.message}
                          </span>
                        )}
                      </Form.Group>
                    </div>
                  </div>

                  <div className="col-md-6">
                    {" "}
                    <div className="main-form-section mt-3">
                      <Form.Group>
                        <Form.Label>Confirm Password</Form.Label>
                        <InputGroup>
                          <Form.Control
                            type="text"
                            name="confirmPassword"
                            placeholder="Confirm Password"
                            className={classNames("", {
                              "is-invalid": errors?.confirmPassword,
                            })}
                            {...register("confirmPassword", {
                              required: "Confirm Password is required",
                              minLength: {
                                value: 8,
                                message:
                                  "Password must be at least 8 characters long",
                              },
                              validate: (value) =>
                                value === password ||
                                "The passwords do not match",
                            })}
                          />
                        </InputGroup>
                        {errors.confirmPassword && (
                          <span className="text-danger">
                            {errors.confirmPassword.message}
                          </span>
                        )}
                      </Form.Group>
                    </div>
                  </div>

                  <div className="col-md-12">
                    {" "}
                    <div className="main-form-section mt-3">
                      <Row className="justify-content-start">
                        <Form.Label className="text-left">
                          Profile Image
                        </Form.Label>

                        <Form.Group>
                          <Form.Control
                            className={classNames("", {
                              "is-invalid": errors?.image,
                            })}
                            type="file"
                            {...register("image", {
                              // required: "Image is required",
                              // validate: async (value) => {
                              //   if (typeof value !== "string") {
                              //     const fileTypes = ["jpg", "png", "jpeg"];
                              //     const fileType = value[0].name?.split(".")[1];
                              //     if (!fileTypes.includes(fileType)) {
                              //       return `please upload a valid file format. (${fileTypes})`;
                              //     }
                              //     const sizes = await getDimension(value[0]);
                              //     if (
                              //       sizes.width !== 420 &&
                              //       sizes.height !== 520
                              //     ) {
                              //       return "Image width and height must be 420 px and 520 px";
                              //     }
                              //     const fileSize = Math.round(
                              //       value[0].size / 1024
                              //     );
                              //     if (fileSize > 500) {
                              //       return "file size must be lower than 500kb";
                              //     }
                              //   }
                              // },
                            })}
                            accept=".jpg, .jpeg, .png"
                          />
                        </Form.Group>
                        {errors.image && (
                          <span className="text-danger">
                            {errors.image.message}
                          </span>
                        )}
                      </Row>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="main-form-section mt-3">
                      <Form.Label column sm={12} className="text-right">
                        Profile Image Preview
                      </Form.Label>
                      <Col sm={9}>
                        {typeof getValues("image") == "string" ? (
                          <div className="image-preview-container">
                            <img
                              src={IMG_URL + getValues("image")}
                              alt="Preview"
                              className="image-preview"
                              style={{ width: "150px", height: "130px" }}
                            />
                          </div>
                        ) : (
                          imageFile &&
                          imageFile?.length > 0 && (
                            <div className="image-preview-container">
                              <img
                                // src={URL.createObjectURL(getValues("image")[0])}
                                src={URL?.createObjectURL(imageFile[0])}
                                alt="Preview"
                                className="image-preview"
                                style={{ width: "100px", height: "100px" }}
                              />
                            </div>
                          )
                        )}
                      </Col>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="main-form-section mt-3">
                      <Row className="justify-content-start">
                        <Form.Label className="text-left">
                          Aadhar Card
                        </Form.Label>

                        <Form.Group>
                          <Form.Control
                            className={classNames("", {
                              "is-invalid": errors?.aadhar_card,
                            })}
                            type="file"
                            {...register("aadhar_card", {
                              // required: "Aadhar Card is required",
                              // validate: async (value) => {
                              //   if (typeof value !== "string") {
                              //     const fileTypes = ["jpg", "png", "jpeg"];
                              //     const fileType = value[0].name?.split(".")[1];
                              //     if (!fileTypes.includes(fileType)) {
                              //       return `please upload a valid file format. (${fileTypes})`;
                              //     }
                              //     const sizes = await getDimension(value[0]);
                              //     if (
                              //       sizes.width !== 420 &&
                              //       sizes.height !== 520
                              //     ) {
                              //       return "Image width and height must be 420 px and 520 px";
                              //     }
                              //     const fileSize = Math.round(
                              //       value[0].size / 1024
                              //     );
                              //     if (fileSize > 500) {
                              //       return "file size must be lower than 500kb";
                              //     }
                              //   }
                              // },
                            })}
                            accept=".jpg, .jpeg, .png"
                            onChange={handleFileChange}
                          />
                        </Form.Group>
                        {errors.aadhar_card && (
                          <span className="text-danger">
                            {errors.aadhar_card.message}
                          </span>
                        )}
                      </Row>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="main-form-section mt-3">
                      <Form.Label column sm={12} className="text-right">
                        Aadhar Card Preview
                      </Form.Label>
                      <Col sm={9}>
                        {typeof getValues("aadhar_card") == "string" ? (
                          <div className="image-preview-container">
                            <img
                              src={IMG_URL + getValues("aadhar_card")}
                              alt="Preview"
                              className="image-preview"
                              style={{ width: "150px", height: "130px" }}
                            />
                          </div>
                        ) : (
                          aadharFile &&
                          aadharFile?.length > 0 && (
                            <div className="image-preview-container">
                              <img
                                // src={URL.createObjectURL(getValues("image")[0])}
                                src={URL?.createObjectURL(aadharFile[0])}
                                alt="Preview"
                                className="image-preview"
                                style={{ width: "100px", height: "100px" }}
                              />
                            </div>
                          )
                        )}
                      </Col>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="main-form-section mt-3">
                      <Row className="justify-content-start">
                        <Form.Label className="text-left">PAN Card</Form.Label>

                        <Form.Group>
                          <Form.Control
                            className={classNames("", {
                              "is-invalid": errors?.pan_card,
                            })}
                            type="file"
                            {...register("pan_card", {
                              // required: "PAN Card is required",
                              // validate: async (value) => {
                              //   if (typeof value !== "string") {
                              //     const fileTypes = ["jpg", "png", "jpeg"];
                              //     const fileType = value[0].name?.split(".")[1];
                              //     if (!fileTypes.includes(fileType)) {
                              //       return `please upload a valid file format. (${fileTypes})`;
                              //     }
                              //     const sizes = await getDimension(value[0]);
                              //     if (
                              //       sizes.width !== 420 &&
                              //       sizes.height !== 520
                              //     ) {
                              //       return "Image width and height must be 420 px and 520 px";
                              //     }
                              //     const fileSize = Math.round(
                              //       value[0].size / 1024
                              //     );
                              //     if (fileSize > 500) {
                              //       return "file size must be lower than 500kb";
                              //     }
                              //   }
                              // },
                            })}
                            accept=".jpg, .jpeg, .png"
                            onChange={handlePanFileChange}
                          />
                        </Form.Group>
                        {errors.pan_card && (
                          <span className="text-danger">
                            {errors.pan_card.message}
                          </span>
                        )}
                      </Row>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="main-form-section mt-3">
                      <Form.Label column sm={12} className="text-right">
                        Pan Card Preview
                      </Form.Label>
                      <Col sm={9}>
                        {typeof getValues("pan_card") == "string" ? (
                          <div className="image-preview-container">
                            <img
                              src={IMG_URL + getValues("pan_card")}
                              alt="Preview"
                              className="image-preview"
                              style={{ width: "150px", height: "130px" }}
                            />
                          </div>
                        ) : (
                          panFile &&
                          panFile?.length > 0 && (
                            <div className="image-preview-container">
                              <img
                                // src={URL.createObjectURL(getValues("image")[0])}
                                src={URL?.createObjectURL(panFile[0])}
                                alt="Preview"
                                className="image-preview"
                                style={{ width: "100px", height: "100px" }}
                              />
                            </div>
                          )
                        )}
                      </Col>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="main-form-section mt-3">
                      <Row className="justify-content-start">
                        <Form.Label className="text-left">
                          Driving Licence Card
                        </Form.Label>
                        <Form.Group>
                          <Form.Control
                            className={classNames("", {
                              "is-invalid": errors?.driving_licence,
                            })}
                            type="file"
                            {...register("driving_licence", {
                              // required: "Driving Licence is required",
                              // validate: async (value) => {
                              //   if (typeof value !== "string") {
                              //     const fileTypes = ["jpg", "png", "jpeg"];
                              //     const fileType = value[0].name?.split(".")[1];
                              //     if (!fileTypes.includes(fileType)) {
                              //       return `please upload a valid file format. (${fileTypes})`;
                              //     }
                              //     const sizes = await getDimension(value[0]);
                              //     if (
                              //       sizes.width !== 420 &&
                              //       sizes.height !== 520
                              //     ) {
                              //       return "Image width and height must be 420 px and 520 px";
                              //     }
                              //     const fileSize = Math.round(
                              //       value[0].size / 1024
                              //     );
                              //     if (fileSize > 500) {
                              //       return "file size must be lower than 500kb";
                              //     }
                              //   }
                              // },
                            })}
                            accept=".jpg, .jpeg, .png"
                            onChange={handleDLicenceFileChange}
                          />
                        </Form.Group>
                        {errors.driving_licence && (
                          <span className="text-danger">
                            {errors.driving_licence.message}
                          </span>
                        )}{" "}
                      </Row>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="main-form-section mt-3">
                      <Form.Label column sm={12} className="text-right">
                        Driving Licence Preview
                      </Form.Label>
                      <Col sm={9}>
                        {typeof getValues("driving_licence") == "string" ? (
                          <div className="image-preview-container">
                            <img
                              src={IMG_URL + getValues("driving_licence")}
                              alt="Preview"
                              className="image-preview"
                              style={{ width: "150px", height: "130px" }}
                            />
                          </div>
                        ) : (
                          drivingLicenceFile &&
                          drivingLicenceFile?.length > 0 && (
                            <div className="image-preview-container">
                              <img
                                // src={URL.createObjectURL(getValues("image")[0])}
                                src={URL?.createObjectURL(
                                  drivingLicenceFile[0]
                                )}
                                alt="Preview"
                                className="image-preview"
                                style={{ width: "100px", height: "100px" }}
                              />
                            </div>
                          )
                        )}
                      </Col>
                    </div>
                  </div>
                </div>
              </Col>

              {/* <Col lg={6}> */}

              {/* </Col> */}

              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>

                  <SaveButton
                    name={"save"}
                    handleSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default EditOffCanvance;
