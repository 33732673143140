import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

// *******************toster****************************
import Select from "react-select";
import ModalSave from "../../common/ModelSave";
import { CancelButton, SaveButton } from "../../common/Button";
import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import JoditEditor from "jodit-react";
library.add(fas);

const AddOffCanvance = (props) => {
  const { postData, getData, Select2Data, IMG_URL } = useContext(Context);

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    getValues,
  } = useForm();
  const imageFile = watch("image");

  const onSubmit = async (data) => {
    try {
      const DataToSend = new FormData();
      DataToSend.append("country_id", data?.country_id?.value);
      DataToSend.append("state_id", data?.state_id?.value);
      DataToSend.append("city_id", data?.city_id?.value);
      DataToSend.append("work_module_id", data?.work_module_id?.value);
      DataToSend.append("job_type_id", data?.job_type_id?.value);
      DataToSend.append("department_id", data?.department_id?.value);
      DataToSend.append("qualification_id", data?.qualification_id?.value);
      DataToSend.append("name", data?.name);
      DataToSend.append("exp_from", data?.exp_from);
      DataToSend.append("exp_to", data?.exp_to);
      DataToSend.append("job_responsibilities", data?.job_responsibilities);
      DataToSend.append("requirement", data?.requirement);
      DataToSend.append("image", data?.image[0]);
      const response = await postData(`/career/jobs`, DataToSend);

      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [workModule, setWorkModule] = useState([]);
  const [jobType, setJobType] = useState([]);
  const [qualification, setQualification] = useState([]);
  const [department, setDepartment] = useState([]);

  const GetAllCountry = async () => {
    const response = await getData("/allcountry");

    if (response?.success) {
      setCountry(await Select2Data(response?.data, "country_id"));
    }
  };

  const GetAllState = async (id) => {
    const response = await getData(`/allstate/${id}`);

    if (response?.success) {
      setState(await Select2Data(response?.data, "state_id"));
    }
  };

  const GetAllCity = async (id) => {
    const response = await getData(`/allcity/${id}`);

    if (response?.success) {
      setCity(await Select2Data(response?.data, "city_id"));
    }
  };

  const GetAllWorkModule = async () => {
    const response = await getData(`/all-work-module`);

    if (response?.success) {
      setWorkModule(await Select2Data(response?.data, "work_module_id"));
    }
  };

  const GetAllJobType = async () => {
    const response = await getData(`/all-job-type`);

    if (response?.success) {
      setJobType(await Select2Data(response?.data, "job_type_id"));
    }
  };

  const GetAllQualification = async () => {
    const response = await getData(`/all-qualification`);

    if (response?.success) {
      setQualification(await Select2Data(response?.data, "qualification_id"));
    }
  };

  const GetAllDepartment = async () => {
    const response = await getData(`/all-department`);

    if (response?.success) {
      setDepartment(await Select2Data(response?.data, "department_id"));
    }
  };

  useEffect(() => {
    GetAllCountry();
    GetAllState();
    GetAllCity();
    GetAllWorkModule();
    GetAllJobType();
    GetAllQualification();
    GetAllDepartment();
  }, []);

  const [charCount, setCharCount] = useState(0);
  const maxCharLimit = 100;

  const handleInputChange = (e) => {
    setCharCount(e.target.value.length);
  };

  return (
    <>
      <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Add Jobs</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={() => handleSubmit(onSubmit)}
            role="form"
            // className="stateclass"
          >
            <Row>
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Country</Form.Label>
                    <Controller
                      name="country_id" // name of the field
                      {...register("country_id", {
                        required: "Select Country",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.country_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={country}
                          onChange={(selectedOption) => {
                            field.onChange(selectedOption?.value); // Update Controller's value
                            GetAllState(selectedOption?.value);
                            setValue("country_id", selectedOption);
                            setValue("state_id", "");
                            setValue("city_id", "");
                          }}
                        />
                      )}
                    />
                    {errors.country_id && (
                      <span className="text-danger">
                        {errors.country_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>State</Form.Label>
                    <Controller
                      name="state_id" // name of the field
                      {...register("state_id", {
                        required: "Select State",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.state_id ? "red" : baseStyles,
                            }),
                          }}
                          {...field}
                          options={state}
                          onChange={(selectedOption) => {
                            field.onChange(selectedOption?.value);
                            GetAllCity(selectedOption?.value);
                            setValue("state_id", selectedOption);
                            setValue("city_id", "");
                          }}
                        />
                      )}
                    />
                    {errors.state_id && (
                      <span className="text-danger">
                        {errors.state_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>City</Form.Label>
                    <Controller
                      name="city_id"
                      {...register("city_id", {
                        required: "Select City",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.city_id ? "red" : baseStyles,
                            }),
                          }}
                          {...field}
                          options={city}
                        />
                      )}
                    />
                    {errors.city_id && (
                      <span className="text-danger">
                        {errors.city_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Job Profile Name</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder="Job Profile Name"
                          className={classNames("", {
                            "is-invalid": errors?.name,
                          })}
                          {...register("name", {
                            required: "Name is required",
                            maxLength: {
                              value: maxCharLimit,
                              message: `Name cannot exceed ${maxCharLimit} characters`,
                            },
                          })}
                          maxLength={maxCharLimit}
                          onChange={handleInputChange}
                        />
                      </InputGroup>{" "}
                      <div className="text-end">
                        <small>{maxCharLimit} characters Limit </small>
                      </div>
                      {errors.name && (
                        <span className="text-danger">
                          {errors.name.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Experience From (In years)</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="exp_from"
                          placeholder="Experience From"
                          className={classNames("", {
                            "is-invalid": errors?.exp_from,
                          })}
                          {...register("exp_from", {
                            required: "Experience From is required",
                            validate: {
                              minValue: (value) =>
                                value >= 1 ||
                                "Experience From should not be less than 1",
                              maxValue: (value) =>
                                value <= 50 ||
                                "Experience From should not be more than 50",
                            },
                          })}
                          onInput={(e) => {
                            let value = e.target.value;

                            // Allow only digits and one decimal point
                            value = value.replace(/[^0-9.]/g, ""); // Remove any non-numeric or non-decimal characters
                            value = value.replace(/(\..*)\./g, "$1"); // Allow only one decimal point

                            // Ensure up to two decimal points
                            const decimalIndex = value.indexOf(".");
                            if (
                              decimalIndex !== -1 &&
                              value.length - decimalIndex - 1 > 2
                            ) {
                              value = value.substring(0, decimalIndex + 3);
                            }

                            // Restrict input between 1 and 100
                            const numericValue = parseFloat(value);
                            if (numericValue > 50) value = "50";
                            if (numericValue < 1) value = "";

                            e.target.value = value;
                          }}
                        />
                      </InputGroup>
                      {errors.exp_from && (
                        <span className="text-danger">
                          {errors.exp_from.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Experience To (In years)</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="exp_to"
                          placeholder="Experience To"
                          min="0"
                          className={classNames("", {
                            "is-invalid": errors?.exp_to,
                          })}
                          {...register("exp_to", {
                            required: "Experience To is required",
                            validate: {
                              minValue: (value) =>
                                value >= 1 ||
                                "Experience To should not be less than 1",
                              maxValue: (value) =>
                                value <= 50 ||
                                "Experience To should not be more than 50",
                              checkExpFrom: (value, context) =>
                                value >= getValues("exp_from") ||
                                "Should not be less than Experience From",
                            },
                          })}
                          onInput={(e) => {
                            let value = e.target.value;

                            // Allow only digits and one decimal point
                            value = value.replace(/[^0-9.]/g, ""); // Remove any non-numeric or non-decimal characters
                            value = value.replace(/(\..*)\./g, "$1"); // Allow only one decimal point

                            // Ensure up to two decimal points
                            const decimalIndex = value.indexOf(".");
                            if (
                              decimalIndex !== -1 &&
                              value.length - decimalIndex - 1 > 2
                            ) {
                              value = value.substring(0, decimalIndex + 3);
                            }

                            // Restrict input between 1 and 100
                            const numericValue = parseFloat(value);
                            if (numericValue > 50) value = "50";
                            if (numericValue < 1) value = "";

                            e.target.value = value;
                          }}
                        />
                      </InputGroup>
                      {errors.exp_to && (
                        <span className="text-danger">
                          {errors.exp_to.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Form.Group>
                    <div className="text-center">
                      <Form.Label>Image</Form.Label>
                    </div>
                    <Form.Group>
                      <Form.Control
                        className={classNames("", {
                          "is-invalid": errors?.image,
                        })}
                        type="file"
                        {...register("image", {
                          required: "Image is required",
                        })}
                        accept=".jpg, .jpeg, .png"
                      />
                    </Form.Group>
                    {errors.image && (
                      <span className="text-danger">
                        {errors.image.message}
                      </span>
                    )}
                  </Form.Group>
                </div>
              </Col>
              <Col className="main-form-section mt-3">
                <div className="main-form-section mt-3">
                  <Form.Label> Image Preview</Form.Label>

                  {imageFile && imageFile?.length > 0 && (
                    <div className="image-preview-container">
                      <img
                        src={URL.createObjectURL(imageFile[0])}
                        alt="Preview"
                        className="image-preview"
                        style={{ width: "150px", height: "130px" }}
                      />
                    </div>
                  )}
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Work Module</Form.Label>
                    <Controller
                      name="work_module_id"
                      {...register("work_module_id", {
                        required: "Select Work Module",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.work_module_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={workModule}
                        />
                      )}
                    />
                    {errors.work_module_id && (
                      <span className="text-danger">
                        {errors.work_module_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Job Type</Form.Label>
                    <Controller
                      name="job_type_id"
                      {...register("job_type_id", {
                        required: "Select Job Type",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.job_type_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={jobType}
                        />
                      )}
                    />
                    {errors.job_type_id && (
                      <span className="text-danger">
                        {errors.job_type_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Department</Form.Label>
                    <Controller
                      name="department_id"
                      {...register("department_id", {
                        required: "Select Deparment",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.department_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={department}
                        />
                      )}
                    />
                    {errors.department_id && (
                      <span className="text-danger">
                        {errors.department_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Qualification</Form.Label>
                    <Controller
                      name="qualification_id"
                      {...register("qualification_id", {
                        required: "Select Deparment",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.qualification_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={qualification}
                        />
                      )}
                    />
                    {errors.qualification_id && (
                      <span className="text-danger">
                        {errors.qualification_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

              <Col sm={12}>
                <Form.Label className="text-center">
                  Job Responsibilities
                </Form.Label>
                <Form.Group>
                  <Controller
                    name="job_responsibilities" // Provide the field name
                    control={control} // Pass the control object from useForm()
                    rules={{ required: "Job Responsibilities is required" }} // Validation rules
                    render={({ field }) => (
                      <JoditEditor
                        value={field?.value}
                        onChange={(newContent) => field.onChange(newContent)}
                        onBlur={field.onBlur}
                        className={classNames("", {
                          "is-invalid": !!errors.job_responsibilities,
                        })}
                        placeholder="Job Responsibilities"
                      />
                    )}
                  />
                </Form.Group>
                {errors.job_responsibilities && (
                  <span className="text-danger">
                    {errors.job_responsibilities.message}
                  </span>
                )}
              </Col>

              <Col sm={12}>
                <Form.Label className="text-center">Requirement</Form.Label>
                <Form.Group>
                  <Controller
                    name="requirement" // Provide the field name
                    control={control} // Pass the control object from useForm()
                    rules={{ required: "Requirement is required" }} // Validation rules
                    render={({ field }) => (
                      <JoditEditor
                        value={field?.value}
                        onChange={(newContent) => field.onChange(newContent)}
                        onBlur={field.onBlur}
                        className={classNames("", {
                          "is-invalid": !!errors.requirement,
                        })}
                        placeholder="Requirement"
                      />
                    )}
                  />
                </Form.Group>
                {errors.requirement && (
                  <span className="text-danger">
                    {errors.requirement.message}
                  </span>
                )}
              </Col>

              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>

                  <SaveButton
                    name={"save"}
                    handleSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
