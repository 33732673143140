import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import colunms from "../../../Components/assets/icons/LINES.png";
import top from "../../../Components/assets/icons/top.png";
import Table from "react-bootstrap/Table";
import { Link } from "react-router-dom";
import Header from "../../Header/Header";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toast, { Toaster } from "react-hot-toast";
import Select from "react-select";
import AddOffCanvance from "./Add";
import EditOffCanvance from "./Edit";
import { Context, usertype } from "../../../utils/context";
import { postData } from "../../../utils/api";
import { formatDate } from "../../../utils/common";
import { Row, Col, Form } from "react-bootstrap";
import Pagination_Holder from "../../common/Pagination_Holder/Pagination_Holder";
import Pagination from "react-bootstrap/Pagination";
library.add(fas);

// ********************************************************************************************************************************************************

const Tables = () => {
  const {
    getData,
    editStatusData,
    deleteData,
    ErrorNotify,
    isAllow,
    Per_Page_Dropdown,
    Select2Data,
    SelectUsers,
    usertype,
    SelectDelivery,
    getDownloadDataExcel,
  } = useContext(Context);

  const [onPageChange, setonPageChange] = useState(1);

  const [totalPages, settotalPages] = useState();

  const handlePageChange = (pageNumber) => {
    console.log(pageNumber, "pageNumber");

    setonPageChange(pageNumber);
  };

  const paginationItems = [];
  for (let page = 1; page <= totalPages; page++) {
    paginationItems.push(
      <Pagination.Item
        key={page}
        active={page === onPageChange}
        onClick={() => handlePageChange(page)}
      >
        {page}
      </Pagination.Item>
    );
  }

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(5);
  const [search, setSearch] = useState("");
  const [reset, setReset] = useState();
  const [show, setShowAdd] = useState(false);
  const [show1, setShowEdit] = useState(0);
  const [option, setOption] = useState();

  const [changeStatus, setChangeStatus] = useState();

  const [selectAllChecked, setSelectAllChecked] = useState([]);
  const [allChecked, setAllChecked] = useState([]);

  const [customerName, setCustomerName] = useState("");
  const [searchProduct, setSearchProduct] = useState("");
  const [searchOrderStatus, setSearchOrderSatatus] = useState("");
  const [searchPaymentStatus, setSearchPaymentSatatus] = useState("");
  const [searchDate, setSearchDate] = useState("");

  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");

  const [deliveryfrom, setDeliveryFrom] = useState("");
  const [deliveryto, setDeliveryTo] = useState("");

  const handleFromChange = (e) => {
    const fromDate = e.target.value;
    setFrom(fromDate);
  };

  const handleToChange = (e) => {
    const toDate = e.target.value;
    setTo(toDate);
  };

  const getDataAll = async () => {
    const response = await getData(
      `/order?page=${onPageChange}&per_page=${perPage}&term=${search}&customerName=${customerName}&from=${
        from ? formatDate(from) : ""
      } &to=${to ? formatDate(to) : ""}&deliveryfrom=${
        deliveryfrom ? formatDate(deliveryfrom) : ""
      } &deliveryto=${deliveryto ? formatDate(deliveryto) : ""}&searchProduct=${
        searchProduct?.value || ""
      }&searchOrderStatus=${
        searchOrderStatus?.value || ""
      }&searchPaymentStatus=${searchPaymentStatus?.value || ""}&date=${
        searchDate ? formatDate(searchDate) : ""
      }`
    );
    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);
    setSearch(response?.data?.search_name);
    settotalPages(response?.data?.totalPages);
    setOption(await Per_Page_Dropdown(response?.data?.totalEntries));

    const newData = response?.data?.data;
    if (newData) {
      const newIds = newData.map((d) => d?.id);
      setAllChecked(newIds);
    }
  };
  useEffect(() => {
    getDataAll();
  }, [
    changeStatus,
    perPage,
    reset,
    show,
    show1,
    customerName,
    searchProduct,
    searchOrderStatus,
    searchPaymentStatus,
    searchDate,
    from,
    to,
    deliveryfrom,
    deliveryto,
    onPageChange,
  ]);

  useEffect(() => {
    console.log("searchPaymentStatus", searchPaymentStatus);
  }, [searchPaymentStatus]);

  const ChangeStatus = async (id) => {
    const response = await editStatusData(`/order/refund/${id}`);
    setChangeStatus(response);
  };

  const [returnMoney, setReturnMoney] = useState();

  const ReturnMoney = async (id) => {
    const response = await postData(`/order/money-return/${id}`);
    window.location.reload();
    setReturnMoney(response);
  };

  // Delete module.....................................................
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordToDeleteId, setRecordToDeleteId] = useState(null);
  const [recordToDeleteName, setRecordToDeleteName] = useState(null);

  const showDeleteRecord = async (id, name) => {
    setShowDeleteModal(true);
    setRecordToDeleteId(id);
    setRecordToDeleteName(name);
  };

  const handleSelectAll = async () => {
    await setSelectAllChecked(allChecked);
  };

  const handleChange = async (e) => {
    const { value, checked } = e.target;

    if (value === "selectAll") {
      if (checked) {
        handleSelectAll();
      } else {
        await setSelectAllChecked([]);
      }
    } else {
      if (checked) {
        await setSelectAllChecked([...selectAllChecked, Number(value)]);
      } else {
        await setSelectAllChecked(
          selectAllChecked.filter((e) => e !== Number(value))
        );
      }
    }
  };
  // Modal function
  const handleClose = () => setShowAdd(false);
  const handleShow = () => setShowAdd(true);

  const handleClose1 = () => setShowEdit(0);
  const handleShow1 = (id) => {
    setShowEdit(id);
  };

  const DeleteRecord = async () => {
    setShowDeleteModal(false);
    if (recordToDeleteId) {
      const response = await deleteData(`/order/${recordToDeleteId}`);
      await ErrorNotify(recordToDeleteName);
      setChangeStatus(response);
      setRecordToDeleteId(null);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setRecordToDeleteId(null);
  };

  //toggle/
  const [visible, setVisibel] = useState({
    col0: true,
    col1: true,
    col2: true,
    col3: true,
    col4: true,
    col5: true,
    col6: true,
    col7: true,
    col8: true,
    col9: true,
    col10: true,
    col11: true,
    col12: true,
    col13: false,
    col14: true,
    col15: true,
    col16: true,
    col17: true,
    col19: true,
    col20: true,
    col25: true,
  });

  //toggle columnns.........
  const toggleColumn = (event, columnName) => {
    event.preventDefault();
    setVisibel((prev) => ({
      ...prev,
      [columnName]: !prev[columnName],
    }));
  };
  const [products, setProducts] = useState({});
  const [orderStatus, setOrderStatus] = useState({});
  const [deliveryBoy, setDeliveryBoy] = useState({});

  const GetAllProducts = async () => {
    const response = await getData("/all-products");

    if (response?.success) {
      setProducts(await Select2Data(response?.data, "product_id"));
    }
  };

  const GetAllOrdderStatus = async () => {
    const response = await getData("/all-order-status");

    if (response?.success) {
      setOrderStatus(await Select2Data(response?.data, "order_status_id"));
    }
  };

  const GetAllDeliveryBoy = async () => {
    const response = await getData("/all-delivery-boy");

    if (response?.success) {
      setDeliveryBoy(await SelectDelivery(response?.data, "delivery_boy_id"));
    }
  };

  useEffect(() => {
    GetAllProducts();
    GetAllOrdderStatus();
    GetAllDeliveryBoy();
  }, []);

  useEffect(() => {
    console.log("selectAllChecked", selectAllChecked);
  }, [selectAllChecked]);

  const handleChangeStatus = async (id) => {
    try {
      if (selectAllChecked.length === 0) {
        alert("Please select at least one row");
        return; // Exit the function early if no rows are selected
      }

      const response = await postData("/order/updateStatus", {
        selectedIds: selectAllChecked,
        newStatusId: id,
      });

      if (response?.success) {
        alert("Order status updated successfully:", response.data);
        getDataAll();
      }

      console.log("Order status updated successfully:", response.data);
    } catch (error) {
      console.error("Error updating order status:", error);
      // Handle errors if necessary
    }
  };

  const handleAssignDeliveryBoy = async (delivery_boy_id, id) => {
    try {
      const response = await postData("/order/assign", {
        selectedIds: delivery_boy_id,
        deliveryboyId: id,
      });

      if (response?.success) {
        alert("Delivery Boy Assign successfully:", response.data);
        getDataAll();
      } else {
        alert(response.message);
      }

      console.log("Delivery Boy Assign successfully:", response.data);
    } catch (error) {
      console.error("Error updating order status:", error);
    }
  };

  const [user, setUser] = useState({});
  const GetUserData = async () => {
    const response = await getData(`/usersingleget`);
    if (response?.success) {
      setUser(response?.data);
    }
  };
  useEffect(() => {
    GetUserData();
  }, []);

  const HandleDownload = async () => {
    if (selectAllChecked.length == 0) {
      alert("Please Select Atleast One Record");
    } else {
      try {
        await getDownloadDataExcel(
          "/order/download",
          selectAllChecked,
          "Order List"
        );
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  return (
    <>
      <div className="main-advancedashboard">
        <Header title={"Orders"} link={"/employee/employee_details"} />
        <section className="AdvanceDashboard">
          <div className="col-xxl-12 col-xl-12  ">
            <div className="row MainRowsec">
              <section className="Tabels tab-radio tab-radio mt-3">
                <div className="">
                  {/* container */}
                  <div className="row">
                    <div className="add me-3">
                      <div className="dropdown">
                        <button
                          className="btn btn-columns dropdown-toggle me-3"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img src={colunms} className="columns me-2 " alt="" />
                          Column Selection{" "}
                          <img src={top} className="top ms-1" alt="" />{" "}
                        </button>
                        <ul className="dropdown-menu">
                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col1")}
                              href="#"
                            >
                              Sr. No.
                              {visible?.col1 ? (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col2")}
                              href="#"
                            >
                              Invoice No.
                              {visible?.col2 ? (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col3")}
                              href="#"
                            >
                              Customer Name
                              {visible?.col3 ? (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>

                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col19")}
                              href="#"
                            >
                              Customer Mobile Number
                              {visible?.col19 ? (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>

                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col4")}
                              href="#"
                            >
                              Order Date
                              {visible?.col4 ? (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>

                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col5")}
                              href="#"
                            >
                              Delivery Date
                              {visible?.col5 ? (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>

                          {/* <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col15")}
                              href="#"
                            >
                              Delivery Time
                              {visible?.col15 ? (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li> */}

                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col6")}
                              href="#"
                            >
                              Product Name
                              {visible?.col6 ? (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>

                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col20")}
                              href="#"
                            >
                              Product Quantity
                              {visible?.col20 ? (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-2"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>

                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col7")}
                              href="#"
                            >
                              Amount
                              {visible?.col7 ? (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col8")}
                              href="#"
                            >
                              Order Status
                              {visible?.col8 ? (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col9")}
                              href="#"
                            >
                              Payment Mode
                              {visible?.col9 ? (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col10")}
                              href="#"
                            >
                              Payment Status
                              {visible?.col10 ? (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col11")}
                              href="#"
                            >
                              Delivery Pincode
                              {visible?.col11 ? (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={(event) => toggleColumn(event, "col14")}
                              href="#"
                            >
                              Open Delivery
                              {visible?.col14 ? (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="ms-4"
                                  icon="fa-solid fa-eye-slash"
                                />
                              )}
                            </Link>
                          </li>
                          {/* <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) =>
                                  toggleColumn(event, "col14")
                                }
                                href="#"
                              >
                                Delivery Boy
                                {visible?.col14 ? (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li> */}
                          {/* <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col13")}
                                href="#"
                              >
                                Action
                                {visible?.col13 ? (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li> */}
                        </ul>
                        {usertype !== "Admin" && (
                          <button
                            className="btn btn-columns dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              // marginLeft: "5px",
                              backgroundColor: "green",
                            }}
                          >
                            <img
                              src={colunms}
                              className="columns me-2 "
                              alt=""
                            />
                            Change Order Status{" "}
                            <img src={top} className="top ms-1" alt="" />{" "}
                          </button>
                        )}

                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          {Object.keys(orderStatus).length > 0 &&
                            Object.entries(orderStatus).map(([id, status]) => (
                              <button
                                className="dropdown-item"
                                key={id}
                                onClick={() => handleChangeStatus(status.value)}
                              >
                                {status.label}{" "}
                              </button>
                            ))}
                        </div>
                      </div>
                    </div>
                    <div className="border-line my-3"></div>
                    <div className="row">
                      <div className=" col-12">
                        <div className="d-flex mb-3 align-items-center">
                          <div className="show me-2">
                            <p className="show m-0">Show</p>
                          </div>
                          <div className="number me-2">
                            <select
                              className="form-select form-select-sm"
                              aria-label=".form-select-sm example"
                              onChange={(e) => setperPage(e.target.value)}
                            >
                              {option?.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="entries me-5">
                            <p className="show m-0">entries</p>
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="row align-items-end">
                          <div className="col-xxl-2 col-xl-3  col-lg-4  col-md-6 col-12 mb-2">
                            <div className="num ">
                              <Form.Label> Customer Name</Form.Label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Customer Name"
                                id=""
                                value={customerName}
                                onChange={(e) => {
                                  setCustomerName(e.target.value);
                                  handlePageChange(1);
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-xxl-2 col-xl-3  col-lg-4  col-md-6 col-12 mb-2">
                            <div className="num ">
                              <Form.Label> Select Product</Form.Label>
                              <Select
                                options={products}
                                placeholder="Select Product"
                                value={searchProduct}
                                onChange={(e) => {
                                  setSearchProduct(e);
                                  handlePageChange(1);
                                }}
                              />
                            </div>
                          </div>

                          <div className=" col-xxl-2 col-xl-3  col-lg-4  col-md-6 col-12 mb-2">
                            <div className="num ">
                              <Form.Label> Sort By Order Status</Form.Label>
                              <Select
                                options={orderStatus}
                                placeholder="Sort By Order Status"
                                value={searchOrderStatus}
                                onChange={(e) => {
                                  setSearchOrderSatatus(e);
                                  handlePageChange(1);
                                }}
                              />
                            </div>
                          </div>

                          <div className=" col-xxl-2 col-xl-3  col-lg-4  col-md-6 col-12 mb-2">
                            <div className="num ">
                              <Form.Label> Payment Status</Form.Label>
                              <Select
                                options={[
                                  { label: "Paid", value: "1" },
                                  { label: "Unpaid", value: "0" },
                                ]}
                                // value={searchPaymentStatus}
                                placeholder="Payment Status"
                                value={searchPaymentStatus}
                                onChange={(e) => {
                                  setSearchPaymentSatatus(e);
                                  handlePageChange(1);
                                }}
                              />
                            </div>
                          </div>

                          <div className=" col-xxl-2 col-xl-3  col-lg-4  col-md-6 col-12 mb-2">
                            <Form.Label> From</Form.Label>
                            <input
                              type="date"
                              className="form-control"
                              value={from}
                              onChange={(e) => {
                                setFrom(e.target.value);
                                handlePageChange(1);
                              }}
                            />
                          </div>

                          <div className=" col-xxl-2 col-xl-3  col-lg-4  col-md-6 col-12 mb-2">
                            <Form.Label> To</Form.Label>
                            <input
                              type="date"
                              className="form-control"
                              value={to}
                              onChange={(e) => {
                                setTo(e.target.value);
                                handlePageChange(1);
                              }}
                              min={from}
                            />
                          </div>

                          <div className=" col-xxl-2 col-xl-3  col-lg-4  col-md-6 col-12 mb-2">
                            <Form.Label>Delivery From</Form.Label>
                            <input
                              type="date"
                              className="form-control"
                              value={deliveryfrom}
                              onChange={(e) => {
                                setDeliveryFrom(e.target.value);
                                handlePageChange(1);
                              }}
                            />
                          </div>

                          <div className=" col-xxl-2 col-xl-3  col-lg-4  col-md-6 col-12 mb-2">
                            <Form.Label>Delivery To</Form.Label>
                            <input
                              type="date"
                              className="form-control"
                              value={deliveryto}
                              onChange={(e) => {
                                setDeliveryTo(e.target.value);
                                handlePageChange(1);
                              }}
                              min={deliveryfrom}
                            />
                          </div>

                          <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-6 col-12 mb-2">
                            <button
                              className="btn btn-success "
                              onClick={HandleDownload}
                            >
                              Download data in Excel
                              <FontAwesomeIcon
                                icon="fa-solid fa-file-lines"
                                className="ms-2"
                              />
                            </button>
                          </div>

                          <div className=" col-xxl-1 col-xl-2  col-lg-2  col-md-2 col-12 mb-2">
                            <div className="Search-1">
                              <button
                                type="button"
                                onClick={() => {
                                  setSearchPaymentSatatus("");
                                  setSearchOrderSatatus("");
                                  setSearchProduct("");
                                  setCustomerName("");
                                  setSearchDate("");
                                  setFrom("");
                                  setTo("");
                                  setDeliveryFrom("");
                                  setDeliveryTo("");
                                  setReset(!reset);
                                }}
                                className="btn btn-reset w-100"
                              >
                                Reset
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border-line my-3"></div>
                  <div className="row mt-3">
                    <div className="data table-responsive">
                      <Table striped bordered hover responsive center>
                        <thead>
                          <tr className="">
                            <th>
                              <input
                                type="checkbox"
                                value="selectAll"
                                checked={
                                  allChecked.length === selectAllChecked.length
                                }
                                onChange={handleChange}
                                id="selectAll"
                                className="me-1"
                              ></input>
                              Select
                            </th>
                            {visible.col1 && <th className="sr">Sr. No.</th>}
                            {visible.col2 && (
                              <th className="tax-name">Invoice No.</th>
                            )}
                            {visible.col3 && (
                              <th className="tax-name">Customer Name</th>
                            )}{" "}
                            {visible.col19 && (
                              <th className="tax-name">
                                Customer Mobile Number
                              </th>
                            )}
                            {visible.col6 && (
                              <th className="tax-name">Product Name</th>
                            )}{" "}
                            {visible.col20 && (
                              <th className="tax-name">Product Quantity</th>
                            )}{" "}
                            {visible.col14 && user?.id === 1 && (
                              <th className="tax-name">Seller Name</th>
                            )}
                            {visible.col10 && (
                              <th className="tax-name">Payment Status</th>
                            )}{" "}
                            {visible.col7 && (
                              <th className="tax-name">Amount</th>
                            )}
                            {visible.col9 && (
                              <th className="tax-name">Payment Mode</th>
                            )}{" "}
                            {visible.col8 && (
                              <th className="tax-name">Order Status</th>
                            )}
                            {visible.col4 && (
                              <th className="tax-name">Order Date</th>
                            )}
                            {visible.col5 && (
                              <th className="tax-name">Delivery Date</th>
                            )}{" "}
                            {visible.col11 && (
                              <th className="tax-name">Delivery Pincode</th>
                            )}
                            {visible.col13 && (
                              <th className="tax-name">Action</th>
                            )}
                            {visible.col14 && (
                              <th className="tax-name">Open Delivery</th>
                            )}{" "}
                            {visible.col17 && (
                              <th className="tax-name">Delivery Instruction</th>
                            )}
                            {visible.col16 && (
                              <th className="tax-name">Delivery Boy</th>
                            )}
                            {visible.col25 && (
                              <th className="tax-name">Assign Delivery Boy</th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {/* {isAllow.includes(5) ? ( */}
                          {data?.data?.data?.length > 0 ? (
                            data.data.data.map((d, index) => {
                              const paginatedIndex =
                                (onPageChange - 1) * perPage + index + 1;
                              return (
                                <tr className="" key={index}>
                                  <td>
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      value={d?.id}
                                      name="perselected"
                                      checked={selectAllChecked.includes(d?.id)}
                                      onChange={handleChange}
                                      id="flexCheckDefault"
                                    />
                                  </td>
                                  {visible.col1 && <td>{paginatedIndex}.</td>}{" "}
                                  {visible.col2 && <td>{d?.invoice_number}</td>}
                                  {visible.col3 && (
                                    <td>{d?.user?.first_name}</td>
                                  )}
                                  {visible.col19 && (
                                    <td>{d?.user?.contact_no}</td>
                                  )}
                                  {visible.col6 && (
                                    <td>
                                      {d?.orders_details?.map(
                                        (orderDetail, index) => (
                                          <div key={index}>
                                            {orderDetail?.product?.name}
                                          </div>
                                        )
                                      )}
                                    </td>
                                  )}
                                  {visible.col20 && (
                                    <td>
                                      {d?.orders_details?.map(
                                        (orderDetail, index) => (
                                          <div key={index}>
                                            {orderDetail?.quantity}
                                          </div>
                                        )
                                      )}
                                    </td>
                                  )}
                                  {visible.col14 && user.id === 1 && (
                                    <td>
                                      {d?.orders_details?.map(
                                        (orderDetail, index) => (
                                          <div key={index}>
                                            {
                                              orderDetail?.product?.user
                                                ?.first_name
                                            }
                                          </div>
                                        )
                                      )}
                                    </td>
                                  )}
                                  {visible.col10 && (
                                    <td>
                                      <div className="form-check form-switch">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          role="switch"
                                          disabled={user.id === 1}
                                          style={{
                                            backgroundColor: d.payment_status
                                              ?.status
                                              ? "green"
                                              : "red",
                                          }}
                                          checked={d.payment_status?.status}
                                          id={`flexSwitchCheckDefault${d?.id}`}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={`flexSwitchCheckDefault${d?.id}`}
                                        >
                                          {d.payment_status?.status
                                            ? "Paid"
                                            : "Unpaid"}
                                        </label>
                                      </div>
                                    </td>
                                  )}
                                  {visible.col7 && <td>{d?.amount}</td>}
                                  {visible.col9 && <td>{d?.payment_mode}</td>}
                                  {visible.col8 && (
                                    <td>{d?.order_status?.order_status}</td>
                                  )}
                                  {visible.col4 && (
                                    <td>{d?.createdAt.split("T")[0]}</td>
                                  )}
                                  {visible.col5 && (
                                    <td>
                                      {
                                        d?.delivery_date
                                          ?.replace("T", " ")
                                          .split(".")[0]
                                      }
                                    </td>
                                  )}
                                  {visible.col11 && (
                                    <td>{d?.users_address?.pincode?.name}</td>
                                  )}
                                  {visible.col14 && (
                                    <td>
                                      {d?.open_delivery == 1 ? "Yes" : "No"}
                                    </td>
                                  )}
                                  {visible.col17 && (
                                    <td>{d?.delivery_instruction}</td>
                                  )}
                                  {visible.col16 && (
                                    <td>{d?.delivery_boy?.first_name}</td>
                                  )}
                                  {d.order_status_id == 5 &&
                                    d.payment_status?.status === 1 &&
                                    usertype === "Finance" &&
                                    visible.col17 && (
                                      <td>
                                        <button
                                          type="button"
                                          className="btn btn-primary"
                                          onClick={() => ReturnMoney(d.id)}
                                        >
                                          Return Money
                                        </button>
                                      </td>
                                    )}
                                  {d.order_status_id == 7 &&
                                    "Refund Successful"}
                                  {visible.col13 && (
                                    <td>
                                      <div className="d-flex">
                                        {/* Action buttons can go here */}
                                      </div>
                                    </td>
                                  )}
                                  {visible.col25 && (
                                    <td>
                                      {usertype !== "Admin" &&
                                      usertype !== "Seller" ? (
                                        d.delivery_boy?.first_name
                                      ) : d.order_status_id != 5 &&
                                        d.order_status_id != 4 &&
                                        d.delivery_boy_id == null ? (
                                        <>
                                          <button
                                            className="btn btn-columns dropdown-toggle"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                            style={{ backgroundColor: "green" }}
                                          >
                                            <img
                                              src={colunms}
                                              className="columns me-2"
                                              alt=""
                                            />
                                            Assign Delivery Boy
                                            <img
                                              src={top}
                                              className="top ms-1"
                                              alt=""
                                            />
                                          </button>
                                          <div
                                            className="dropdown-menu"
                                            aria-labelledby="dropdownMenuButton"
                                          >
                                            {Object.keys(deliveryBoy).length >
                                              0 &&
                                              Object.entries(deliveryBoy).map(
                                                ([id, status]) => (
                                                  <button
                                                    className="dropdown-item"
                                                    key={id}
                                                    onClick={() =>
                                                      handleAssignDeliveryBoy(
                                                        d.id,
                                                        status.value
                                                      )
                                                    }
                                                  >
                                                    {status.label ||
                                                      `Delivery Boy ${id}`}
                                                  </button>
                                                )
                                              )}
                                          </div>
                                        </>
                                      ) : (
                                        d.delivery_boy?.first_name
                                      )}
                                    </td>
                                  )}
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="19" className="text-center">
                                Data not available
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                      <Pagination_Holder
                        onPageChange={currentPage}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                      />
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>

      {show ? (
        <AddOffCanvance
          handleClose={handleClose}
          setShow={setShowAdd}
          show={show}
        />
      ) : (
        ""
      )}

      {show1 ? (
        <EditOffCanvance
          handleClose={handleClose1}
          setShow={setShowEdit}
          show={show1}
        />
      ) : (
        ""
      )}

      <Toaster position="top-right" />

      {/* <!-- Modal Delete --> */}
      <div className="upload-modal">
        <div
          className={`modal fade ${showDeleteModal ? "show" : ""}`}
          style={{ display: showDeleteModal ? "block" : "none" }}
          id="exampleModaldel"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <h1 className="add-success text-center  mt-2">
                  Are you sure ?
                </h1>
                <p>
                  Do You Really Want to Delete These Record?
                  <br /> This Process CanNot Be Undone{" "}
                </p>
                <div className="button-holder text-center mt-2">
                  <button className="btn btn-yes me-2" onClick={DeleteRecord}>
                    Yes
                  </button>
                  <button className="btn btn-no" onClick={handleDeleteCancel}>
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tables;
