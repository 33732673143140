import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

// *******************toster****************************
import Select from "react-select";
import ModalSave from "../../common/ModelSave";
import Validation from "../../common/FormValidation";
import { CancelButton, SaveButton } from "../../common/Button";

import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
library.add(fas);

const AddOffCanvance = (props) => {
  const { postData, getData, Select2Data, SelectUsers, getDimension } =
    useContext(Context);

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const [products, setProducts] = useState([]);
  const [users, setUser] = useState([]);

  const GetAllCategory = async () => {
    const response = await getData("/all-notification-type");

    if (response?.success) {
      setCountries(await Select2Data(response?.data, "notification_type_id"));
    }
  };

  const GetAllProducts = async () => {
    const response = await getData("/all-products");

    if (response?.success) {
      setProducts(await Select2Data(response?.data, "product_id"));
    }
  };

  const GetAllUsers = async () => {
    const response = await getData("/all-customer");

    if (response?.success) {
      setUser(await SelectUsers(response?.data, "user_id"));
    }
  };

  useEffect(() => {
    GetAllCategory();
    GetAllProducts();
    GetAllUsers();
  }, []);
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const imageFile = watch("image");

  const onSubmit = async (data) => {
    try {
      const DataToSend = new FormData();
      DataToSend.append(
        "notification_type_id",
        data?.notification_type_id?.value
      );
      DataToSend.append("product_id", data?.product_id?.value);

      if (data?.user_id?.value) {
        DataToSend.append("user_id", data?.user_id?.value);
      }
      // DataToSend.append("user_id", data?.user_id?.value || null);
      DataToSend.append("message", data?.message);
      if (data?.image && data.image.length > 0) {
        DataToSend.append("image", data.image[0]);
      }
      const response = await postData(`/notification`, DataToSend);

      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const [charCount, setCharCount] = useState(0);
  const maxCharLimit = 100;

  const handleInputChange = (e) => {
    setCharCount(e.target.value.length);
  };

  return (
    <>
      <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Notification
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={() => handleSubmit(onSubmit)}
            role="form"
            // className="stateclass"
          >
            <Row>
              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Notification Type</Form.Label>
                    <Controller
                      name="notification_type_id" // name of the field
                      {...register("notification_type_id", {
                        required: "Select Notification type",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.notification_type_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={countries}
                        />
                      )}
                    />
                    {errors.notification_type_id && (
                      <span className="text-danger">
                        {errors.notification_type_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Products</Form.Label>
                    <Controller
                      name="product_id" // name of the field
                      {...register("product_id", {
                        required: "Select Product ",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.product_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={products}
                        />
                      )}
                    />
                    {errors.product_id && (
                      <span className="text-danger">
                        {errors.product_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Customer</Form.Label>
                    <Controller
                      name="user_id"
                      {...register("user_id", {
                        // required: "Select Customer",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.user_id ? "red" : baseStyles,
                            }),
                          }}
                          {...field}
                          options={users}
                        />
                      )}
                    />
                    {errors.user_id && (
                      <span className="text-danger">
                        {errors.user_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Message</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="message"
                          placeholder="Message"
                          className={classNames("", {
                            "is-invalid": errors?.message,
                          })}
                          {...register("message", {
                            required: "Message is required",
                            maxLength: {
                              value: maxCharLimit,
                              message: `Name cannot exceed ${maxCharLimit} characters`,
                            },
                          })}
                          maxLength={maxCharLimit}
                          onChange={handleInputChange}
                        />
                      </InputGroup>{" "}
                      <div className="text-end">
                        <small>{maxCharLimit} characters Limit </small>
                      </div>
                      {errors.message && (
                        <span className="text-danger">
                          {errors.message.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col lg={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-start">
                    <Form.Label className="text-left">Image</Form.Label>

                    <Form.Group>
                      <Form.Control
                        className={classNames("", {
                          "is-invalid": errors?.image,
                        })}
                        type="file"
                        {...register("image", {
                          required: "Image is required",
                          validate: async (value) => {
                            if (typeof value !== "string") {
                              const fileTypes = ["jpg", "png", "jpeg"];
                              const fileType = value[0].name?.split(".")[1];

                              // if (!fileTypes.includes(fileType)) {
                              //   return `please upload a valid file format. (${fileTypes})`;
                              // }

                              // const sizes = await getDimension(value[0]);
                              // if (
                              //   sizes.width !== 1296 &&
                              //   sizes.height !== 465
                              // ) {
                              //   return "Image width and height must be 1296 px and 465 px";
                              // }

                              // const fileSize = Math.round(
                              //   value[0].size / 1024
                              // );
                              // if (fileSize > 500) {
                              //   return "file size must be lower than 500kb";
                              // }
                            }
                          },
                        })}
                        accept=".jpg, .jpeg, .png"
                      />
                    </Form.Group>
                    {errors.image && (
                      <span className="text-danger">
                        {errors.image.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col>

              <Col className="main-form-section mt-3">
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Image Preview</Form.Label>

                    {imageFile && imageFile?.length > 0 && (
                      <div
                        className="image-preview-container mt-3"
                        style={{ marginLeft: "110px" }}
                      >
                        <img
                          // src={URL.createObjectURL(getValues("image")[0])}
                          src={URL?.createObjectURL(imageFile[0])}
                          alt="Preview"
                          className="image-preview"
                          style={{ width: "648px", height: "198px" }}
                        />
                      </div>
                    )}
                  </Row>
                </div>
              </Col>

              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>

                  <SaveButton
                    name={"save"}
                    handleSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
