import React, { useContext, useEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./AdvanceDashboard.css";
import Header from "../../Header/Header";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import "react-circular-progressbar/dist/styles.css";
import "swiper/swiper-bundle.css";
import CanvasJSReact from "@canvasjs/react-charts";
import { Context } from "../../../utils/context";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import search1 from "../../../Components/assets/icons/search.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable from "datatables.net";
import Button from "react-bootstrap/Button";
import { Link, useLocation } from "react-router-dom";
import { Modal, Table } from "react-bootstrap";
import { Select2Data } from "../../../utils/api";
import Select from "react-select";
import ReactApexChart from "react-apexcharts";
import Monthly_Sell_Chart from "./Monthly_Sell_Chart/Monthly_Sell_Chart";
import MonthlySellChart from "./Monthly_Sell_Chart/Monthly_Sell_Chart";
import Product_pie_chart from "./Product_pie_chart/Product_pie_chart";
import AllProductColumnChart from "./AllProduct-PieChart/Product_pie_chart";
import PieChart from "./Seller_pie_chart/Seller_pie_chart";
import SellerProduct_pie_chart from "./SellerProductPieChart/SellerProduct_pie_chart";
import { useForm, Controller } from "react-hook-form";

import SubscriptionModal from "../../common/SubscriptionModal/SubscriptionModal";
import Pagination_Holder from "../../common/Pagination_Holder/Pagination_Holder";
import Pagination from "react-bootstrap/Pagination";
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

library.add(fas);

const AdvanceDashboard = () => {
  const {
    getData,
    usertype,
    userdata,
    SelectUsers,
    SelectReportAnalysisData,
    getDownloadDataExcel,
  } = useContext(Context);

  console.log(userdata, "userdata");

  console.log(usertype, "usertype");

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(5);
  const [onPageChange, setonPageChange] = useState(1);

  const [totalPages, settotalPages] = useState();

  const handlePageChange = (pageNumber) => {
    console.log(pageNumber, "pageNumber");

    setonPageChange(pageNumber);
  };

  const paginationItems = [];
  for (let page = 1; page <= totalPages; page++) {
    paginationItems.push(
      <Pagination.Item
        key={page}
        active={page === onPageChange}
        onClick={() => handlePageChange(page)}
      >
        {page}
      </Pagination.Item>
    );
  }

  const [countOrders, setCountOrders] = useState("");
  const GetEditData = async () => {
    const res = await getData(`/count-all-orders`);
    setCountOrders(res?.data?.count);
  };
  const [countProducts, setCountProducts] = useState("");
  const GetProductData = async () => {
    const res = await getData(`/count-all-products`);
    setCountProducts(res?.data?.count);
  };
  const [countCustomers, setCountCustomers] = useState("");
  const GetCustomersData = async () => {
    const res = await getData(`/count-all-customers`);
    setCountCustomers(res?.data?.count);
  };
  const [countSellers, setCountSellers] = useState("");
  const GetSellersData = async () => {
    const res = await getData(`/count-all-sellers`);
    setCountSellers(res?.data?.count);
  };
  const [sellerSale, setSellersSell] = useState([]);

  const [months, setMonth] = useState([]);

  const [sales, setSale] = useState([]);

  const [product, setProduct] = useState([]);
  const [productForGraph, setProductForGraph] = useState([]);
  const [productForGraphBySeller, setProductForGraphBySeller] = useState([]);
  const [productCount, setProductCount] = useState([]);
  const [productGraphCount, setProductGraphCount] = useState([]);
  const [productGraphCountBySeller, setProductGraphCountBySeller] = useState(
    []
  );

  const [sellerData, setSellerData] = useState([]);
  const [sellerGraphData, setSellerGraphData] = useState([]);

  const [sellerCount, setSellerCount] = useState([]);
  const [sellerGraphCount, setSellerGraphCount] = useState([]);

  const GetSellersSellData = async () => {
    try {
      const res = await getData(`/all-seller-sell`);
      setSellersSell(res?.data);
    } catch (error) {
      console.error("Error fetching seller data:", error);
    }
  };

  useEffect(() => {
    // Fetch data when component mounts
    GetSellersSellData();
  }, []);

  useEffect(() => {
    const extractedMonths = sellerSale?.data
      ?.map((value) => value.month)
      .filter((month) => month !== undefined);
    setMonth(extractedMonths);

    const extractedSale = sellerSale?.data
      ?.map((value) => value.count)
      .filter((month) => month !== undefined);
    setSale(extractedSale);
  }, [sellerSale]);

  const [inventoryProduct, setInventoryProduct] = useState([]);

  const [search, setSearch] = useState("");
  const [reset, setReset] = useState();
  const [sellerReset, setSellerReset] = useState();
  const [singleVarient, setsingleVarient] = useState("");
  const GetInventoryProduct = async () => {
    const res = await getData(
      `/all-inventry_product?page=${onPageChange}&per_page=${perPage}&term=${search}&singleVarient=${
        singleVarient?.value || ""
      }`
    );
    await setInventoryProduct(res);
    setSearch(res?.data?.search_name);
    settotalPages(res?.data?.totalPages);
    setCurrentPage(res?.data?.currentPage);
    setperPage(res?.data?.per_page);
  };

  useEffect(() => {
    GetEditData();
    GetProductData();
    GetCustomersData();
    GetSellersData();
    // GetInventoryProduct();
  }, []);

  useEffect(() => {
    GetInventoryProduct();
  }, [reset, onPageChange, perPage]);

  const [categories, setCategories] = useState([]);
  const [reportAnalysis, setReportAnalysis] = useState([]);
  const [allSellers, setAllSellers] = useState([]);

  const [productData, setProductData] = useState("");
  const [sellers, setSellers] = useState("");
  const GetAllCategory = async () => {
    const response = await getData("/allcategories");
    if (response?.success) {
      setCategories(await Select2Data(response?.data, "category_id"));
    }
  };

  const [defaultOption, setDefaultOption] = useState(null);

  const GetAllReportAnalysis = async () => {
    const response = await getData("/all-report-analysis");
    if (response?.success) {
      const data = await SelectReportAnalysisData(response?.data);
      setReportAnalysis(data);
    }
  };

  const GetAllOnlySellers = async () => {
    const response = await getData("/all-sellers");
    if (response?.success) {
      const data = await SelectUsers(response?.data);
      setAllSellers(data);
    }
  };

  const GetProductSale = async (days = 30) => {
    try {
      let url = `/most-selling-product`;

      if (days) {
        url += `?daysAgo=${days}`;
      }

      const res = await getData(url);
      setProductData(res?.data?.data);
    } catch (error) {
      console.error("Error fetching seller data:", error);
    }
  };

  const [productGraph, setProductGraph] = useState("");
  const [productGraphBySeller, setProductGraphBySeller] = useState("");
  const [sellerGraph, setSellerGraph] = useState("");

  const GetAllProductGraph = async (days) => {
    try {
      const res = await getData(`/product-graph`);
      setProductGraph(res?.data?.data);
    } catch (error) {
      console.error("Error fetching seller data:", error);
    }
  };

  const [selectedSellerId, setSelectedSellerId] = useState(null);
  // const [modalShow, setModalShow] = useState(false);

  const [selectedReport, setSelectedReport] = useState(null);

  const GetAllProductGraphBySeller = async (id, daysAgo) => {
    try {
      const res = await getData(
        `/product-graph-by-seller/${id}?daysAgo=${daysAgo}`
      );
      setProductGraphBySeller(res?.data?.data);
    } catch (error) {
      console.error("Error fetching seller data:", error);
    }
  };

  useEffect(() => {
    GetAllProductGraphBySeller();
  }, [sellerReset]);

  useEffect(() => {
    const extractedProduct = productData?.top5
      ?.map((value) => value.product?.name)
      .filter((product) => product !== undefined);
    setProduct(extractedProduct);

    const extractedProductCount = productData?.top5
      ?.map((value) => value.productCount)
      .filter((product) => product !== undefined);
    setProductCount(extractedProductCount);
  }, [productData]);

  useEffect(() => {
    const extractedProduct = productGraph?.top5
      ?.map((value) => value.product?.name)
      .filter((product) => product !== undefined);
    setProductForGraph(extractedProduct);

    const extractedProductCount = productGraph?.top5
      ?.map((value) => value.productCount)
      .filter((product) => product !== undefined);
    setProductGraphCount(extractedProductCount);
  }, [productGraph]);

  // ......................................................All Seller Product graph......................................................
  useEffect(() => {
    const extractedProductBySeller = productGraphBySeller?.top5
      ?.map((value) => value.product?.name)
      .filter((product) => product !== undefined);
    setProductForGraphBySeller(extractedProductBySeller);

    const extractedProductCountBySeller = productGraphBySeller?.top5
      ?.map((value) => value.productCount)
      .filter((product) => product !== undefined);
    setProductGraphCountBySeller(extractedProductCountBySeller);
  }, [productGraphBySeller]);
  // ......................................................All Seller Product graph End......................................................
  useEffect(() => {
    const extractedSeller = sellers?.top5
      ?.map((value) => value.seller?.first_name)
      .filter((seller) => seller !== undefined);
    setSellerData(extractedSeller);

    const extractedSellerCount = sellers?.top5
      ?.map((value) => value.productCount)
      .filter((count) => count !== undefined);
    setSellerCount(extractedSellerCount);
  }, [sellers]);

  useEffect(() => {
    const extractedSeller = sellerGraph?.top5
      ?.map((value) => value.seller?.first_name)
      .filter((seller) => seller !== undefined);
    setSellerGraphData(extractedSeller);

    const extractedSellerCount = sellerGraph?.top5
      ?.map((value) => value.productCount)
      .filter((count) => count !== undefined);
    setSellerGraphCount(extractedSellerCount);
  }, [sellerGraph]);

  const GetSellers = async (days = 30) => {
    try {
      let url = `/most-popular-seller`;

      if (days) {
        url += `?daysAgo=${days}`;
      }
      const res = await getData(url);
      setSellers(res?.data?.data);
    } catch (error) {
      console.error("Error fetching seller data:", error);
    }
  };

  const GetSellerGraph = async (days) => {
    try {
      // let url = `/seller-graph`;

      // if (days) {
      //   url += `?daysAgo=${days}`;
      // }
      const res = await getData(`/seller-graph`);
      setSellerGraph(res?.data?.data);
    } catch (error) {
      console.error("Error fetching seller data:", error);
    }
  };

  useEffect(() => {
    GetAllCategory();
    GetAllReportAnalysis();
    GetAllOnlySellers();
    GetProductSale();
    GetSellers();
    GetAllProductGraph();
    GetSellerGraph();
  }, []);

  const cardsData = [
    {
      number: countOrders,
      types: "Orders",
      icon: "fa-cart-shopping",
      className: "#63bff5",
      api: "/order-managements/order",
    },
    {
      number: countProducts,
      types: "Products",
      icon: "fa-solid fa-boxes-stacked",
      className: "#63bff5",
      api: "/product",
    },
  ];

  if (usertype === "Admin") {
    cardsData.push({
      number: countCustomers,
      types: "Customers",
      icon: "fa-solid fa-users",
      className: "#63bff5",
      api: "/employee/customer-details",
    });
  }

  if (usertype === "Admin") {
    cardsData.push({
      number: countSellers,
      types: "Sellers",
      icon: "fa-solid fa-users",
      className: "#63bff5",
      api: "/seller-detail",
    });
  }

  if (usertype === "Admin") {
    cardsData.push({
      number: userdata?.data?.wallet_amount,
      types: "My Wallet Amount",
      icon: "fa-solid fa-users",
      className: "#63bff5",
      api: "/wallet-history",
    });
  }

  useEffect(() => {
    const table = new DataTable("#example");
    return () => {
      table.destroy();
    };
  }, []);

  useEffect(() => {
    const table = new DataTable("#exampleContent");
    return () => {
      table.destroy();
    };
  }, []);

  const colors = ["#63bff5", "#f39c12", "#dd4b39", "#00a65a"];

  const today = new Date();

  const commissionEndDate = new Date(userdata?.commission_e_date);

  const [showTopSelling, setShowTopSelling] = useState(false);

  const handleTopSellingClose = () => setShowTopSelling(false);
  const handleShowTopSelling = () => setShowTopSelling(true);

  const [showTopSellers, setShowTopSellers] = useState(false);

  const handleTopSellersClose = () => setShowTopSellers(false);
  const handleShowTopSellers = () => setShowTopSellers(true);

  return (
    <>
      <div className="main-advancedashboard">
        <section className="AdvanceDashboard">
          <div className="AdvanceDashboardTabs">
            <Header title={"Dashboard"} link={"/masters/social_links"} />
            <div className=""></div>
          </div>
        </section>
        <div className="Main-Section-Advanced-Dashboard">
          <h1 className="title py-2">DashBoard</h1>
          <Row>
            {cardsData.map((item, index) => (
              <Col xxl={3} xl={3} lg={3} md={6} sm={12} xs={12}>
                <Card
                  className="small-box"
                  style={{ backgroundColor: colors[index % colors?.length] }}
                >
                  <div className={item.className}>
                    <div className="content">
                      <div class="inner">
                        <h3>{item.number}</h3>
                        <p>{item.types}</p>
                      </div>
                      <div class="icon">
                        <FontAwesomeIcon icon={item.icon} />
                      </div>
                    </div>

                    <Link to={item?.api}>
                      <p className="mb-0 small-box-footer">
                        More info{" "}
                        <FontAwesomeIcon icon="fa-solid fa-circle-arrow-right" />
                      </p>
                    </Link>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
        <div className="btn-holder mt-3">
          <div className="Search-2 me-2">
            <button
              type="button"
              className="btn btn-selling-product-btn"
              onClick={handleShowTopSelling}
            >
              Top Selling Products
            </button>
          </div>

          {usertype == "Admin" && (
            <div className="Search-2 me-2">
              <button
                type="button"
                className="btn btn-selling-product-btn"
                onClick={handleShowTopSellers}
              >
                Top Sellers
              </button>
            </div>
          )}
        </div>
        {/* {usertype === "Seller" && (
          <div className="Search-1 me-2 text-end">
            <button
              className="subscription-btnnn"
              onClick={() => setModalShow(true)}
            >
              My Subscription
            </button>
          </div>
        )}  */}

        {commissionEndDate < today && usertype == "Seller" && (
          <h2> "Your Login Has Expired"</h2>
        )}

        {usertype == "Admin" && (
          <div className="row me-0 ms-0 mt-3 data-table-holder">
            <div className="col-md-12">
              <h5>
                <b>Stock Inventory</b>
              </h5>

              <div className="row align-items-end">
                <div className=" col-xxl-2 col-xl-3  col-lg-4  col-md-6 col-12 mb-2">
                  <div className="search-holder">
                    <div className="num me-2">
                      <input
                        type="text"
                        className="form-control"
                        id=""
                        placeholder="product/varient"
                        value={search}
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-xxl-1 col-xl-2  col-lg-2  col-md-2 col-12 mb-2">
                  <div className="search-holder">
                    <div className="Search me-2">
                      <Button
                        type="button"
                        onClick={GetInventoryProduct}
                        className="btn btn-search w-100"
                      >
                        <img src={search1} className="search" alt="" />
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-1 col-xl-2  col-lg-2  col-md-2 col-12 mb-2">
                  <div className="search-holder">
                    <div className="Search-1 me-2">
                      <button
                        type="button"
                        onClick={() => {
                          setSearch("");
                          setsingleVarient("");
                          setReset(!reset);
                        }}
                        className="btn btn-reset w-100"
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="col-xxl-2 col-xl-3  col-lg-4  col-md-6 col-12 mb-23">
              <div className="num ">
                <Select
                  isSearchable
                  options={categories}
                  value={singleVarient}
                  placeholder="Select Category"
                  onChange={(e) => {
                    setsingleVarient(e);
                  }}
                />
              </div>
            </div> */}

              <br />

              <div className="data table-responsive">
                <Table striped bordered hover responsive center>
                  <thead>
                    <tr>
                      <th className="sr">Sr. No.</th>
                      <th className="tax-name">Product</th>
                      <th className="tax-name">Variant</th>
                      <th className="sr">Single Variant</th>
                      <th className="tax-name">Multi Variant Main</th>
                      <th className="tax-name">Multi Sub Variant</th>
                      <th className="tax-name">Unit</th>{" "}
                      <th className="tax-name">Stock Limit </th>
                    </tr>
                  </thead>
                  <tbody>
                    {inventoryProduct?.data?.data?.length > 0 ? (
                      inventoryProduct.data.data.map((d, index) => {
                        const paginatedIndex =
                          (onPageChange - 1) * perPage + index + 1;
                        return (
                          <tr key={d.id || index}>
                            <td>{paginatedIndex}.</td>
                            <td>{d?.name}</td>
                            <td>{d?.type}</td>
                            {/* Product Variants */}
                            <td>
                              {d?.type === "Multiple"
                                ? "-"
                                : d?.product_variants?.map((value, i) => (
                                    <div key={value.id || i}>
                                      {value?.v_name}
                                      <hr />
                                    </div>
                                  ))}
                            </td>
                            {/* Multiple Mains */}
                            <td>
                              {d?.type === "Single"
                                ? "-"
                                : d?.product_multiple_mains?.map((value) => (
                                    <div key={value?.id}>
                                      {value?.varient_name}
                                      <hr />
                                    </div>
                                  ))}
                            </td>
                            {/* Sub Variants */}
                            <td>
                              {d?.type === "Single"
                                ? "-"
                                : d?.product_multiple_mains?.map((main) => (
                                    <div key={main?.id}>
                                      {main?.product_multiple_variants?.map(
                                        (variant, idx) => (
                                          <div key={variant?.id || idx}>
                                            {variant?.varient_sub_name}
                                          </div>
                                        )
                                      )}
                                      <hr />
                                    </div>
                                  ))}
                            </td>
                            {/* Units */}
                            <td>
                              {d?.type === "Single"
                                ? d?.product_variants?.map((value, i) => (
                                    <div key={value.id || i}>
                                      {value?.unit}
                                      <hr />
                                    </div>
                                  ))
                                : d?.product_multiple_mains?.map(
                                    (main, idx) => (
                                      <div key={main.id || idx}>
                                        {main?.product_multiple_variants?.map(
                                          (variant, id) => (
                                            <div key={variant.id || id}>
                                              {variant?.unit}
                                            </div>
                                          )
                                        )}
                                        <hr />
                                      </div>
                                    )
                                  )}
                            </td>
                            {/* Stock Limit */}
                            <td>
                              {d?.type === "Single"
                                ? d?.product_variants?.map((value, i) => (
                                    <div key={value.id || i}>
                                      {Number(value?.unit) <=
                                      Number(value?.stock_limit) ? (
                                        <span style={{ color: "red" }}>
                                          Low Stock
                                        </span>
                                      ) : (
                                        <span>{value?.stock_limit}</span>
                                      )}
                                      <hr />
                                    </div>
                                  ))
                                : d?.product_multiple_mains?.map(
                                    (main, idx) => (
                                      <div key={main.id || idx}>
                                        {main?.product_multiple_variants?.map(
                                          (variant, id) => (
                                            <div key={variant.id || id}>
                                              {Number(variant?.unit) <=
                                              Number(variant?.stock_limit) ? (
                                                <span style={{ color: "red" }}>
                                                  Low Stock
                                                </span>
                                              ) : (
                                                <span>
                                                  {variant?.stock_limit}
                                                </span>
                                              )}
                                            </div>
                                          )
                                        )}
                                        <hr />
                                      </div>
                                    )
                                  )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="10" className="text-center">
                          Data not available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                <Pagination_Holder
                  onPageChange={currentPage}
                  totalPages={totalPages}
                  handlePageChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="chart-holder">
        <div className="row ">
          {usertype == "Seller" ? (
            <>
              <div className="col-xl-4 col-lg-6 col-md-12 col-12">
                {usertype == "Seller" && (
                  <div className="chart-heading">
                    <h1>Seller Performance Sale</h1>
                  </div>
                )}
                {usertype == "Seller" && (
                  <MonthlySellChart months={months} sale={sales} />
                )}
              </div>{" "}
              <div className="col-xl-4 col-lg-6 col-md-12 col-12">
                <div className="chart-heading">
                  <h1> Products</h1>
                </div>
                {productForGraph?.length > 0 &&
                  productGraphCount?.length > 0 && (
                    <Product_pie_chart
                      productData={productForGraph}
                      productCount={productGraphCount}
                    />
                  )}
              </div>
            </>
          ) : (
            <>
              {" "}
              <div className=" col-12">
                <div className="chart-heading">
                  <h1>All Products Graph</h1>
                </div>
                {productForGraph?.length > 0 &&
                  productGraphCount?.length > 0 && (
                    <AllProductColumnChart
                      productData={productForGraph}
                      productCount={productGraphCount}
                    />
                  )}
              </div>
              <div className="col-xl-4 col-lg-6 col-md-12 col-12">
                {usertype == "Admin" && (
                  <div className="chart-heading">
                    <h1> All Sellers Report </h1>
                  </div>
                )}
                {sellerGraphData?.length > 0 &&
                  sellerGraphCount?.length > 0 &&
                  usertype == "Admin" &&
                  sellerData?.length > 0 &&
                  sellerCount?.length > 0 && (
                    <PieChart
                      sellerData={sellerGraphData}
                      sellerCount={sellerGraphCount}
                    />
                  )}
              </div>
              <div className="col-xl-4 col-lg-6 col-md-12 col-12">
                {usertype == "Admin" && (
                  <div className="chart-heading">
                    <h1> Seller Products </h1>
                  </div>
                )}
                <div className="top-selling-product-dropdown-holder mb-3">
                  <Select
                    className="mb-3"
                    placeholder={"Seller"}
                    value={
                      selectedSellerId
                        ? allSellers.find(
                            (seller) => seller.value === selectedSellerId
                          )
                        : null
                    }
                    options={allSellers}
                    onChange={(selectedOption) => {
                      setSelectedSellerId(selectedOption?.value);
                      // GetAllProductGraphBySeller(selectedOption?.value);
                    }}
                  />
                  <Select
                    className="mb-3"
                    placeholder={"Search for Number Of Days"}
                    value={selectedReport}
                    options={reportAnalysis}
                    onChange={(selectedReport) => {
                      setSelectedReport(selectedReport);
                      if (selectedSellerId && selectedReport?.name) {
                        GetAllProductGraphBySeller(
                          selectedSellerId,
                          selectedReport?.name
                        );
                      }
                    }}
                  />
                  <div className="Search-1 ms-auto mt-3">
                    <button
                      type="button"
                      onClick={() => {
                        setSelectedSellerId("");
                        setSelectedReport(null);
                        GetAllProductGraphBySeller("");
                        setSellerReset(!sellerReset);
                      }}
                      className="btn btn-danger"
                    >
                      Clear
                    </button>
                  </div>
                </div>

                {productForGraphBySeller?.length > 0 &&
                  productGraphCountBySeller?.length > 0 && (
                    <SellerProduct_pie_chart
                      productData={productForGraphBySeller}
                      productCount={productGraphCountBySeller}
                    />
                  )}
              </div>
            </>
          )}
        </div>
      </div>

      <div className="top-selling-product-modal-holder">
        <Modal
          className="top-selling-product-modal"
          show={showTopSelling}
          onHide={handleTopSellingClose}
          animation={false}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title> Products</Modal.Title>
          </Modal.Header>
          {/* <Button className="dounload-btn-holder ms-2">
            <FontAwesomeIcon
              icon="fa-solid fa-download"
              className="pdf-icon me-2"
              variant="success"
              // className="btn btn-view"
            />{" "}
            <p>Download</p>
          </Button> */}
          <Modal.Body>
            <div className="top-selling-product-modal-content-holder">
              <div className="top-selling-product-dropdown-holder mb-3">
                {/* <Select options={topSellingOptions} /> */}
                <Select
                  className="mb-3"
                  options={reportAnalysis}
                  placeholder={"Select Days"}
                  // value={defaultOption}
                  onChange={(selectedOption) =>
                    GetProductSale(selectedOption?.name)
                  }
                />
              </div>

              <div className="top-selling-table-holder mb-3">
                <div className="table-heading-holder mb-3">
                  <h3>Top selling Products</h3>
                </div>
                <div className="table-responsive">
                  <Table bordered responsive="xl">
                    <thead>
                      <tr>
                        <th>Sr.no</th>
                        <th>Product Name</th>
                        <th>Count</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>{productData?.top1?.product?.name}</td>
                        <td>{productData?.top1?.productCount}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>

              <div className="top-five-selling-table-holder mb-4">
                <div className="table-heading-holder mb-3">
                  <h3>Top five selling Products</h3>
                </div>
                <div className="table-responsive">
                  <Table bordered responsive="xl">
                    <thead>
                      <tr>
                        <th>Sr.no</th>
                        <th>Product Name</th>
                        <th>Count</th>
                      </tr>
                    </thead>
                    <tbody>
                      {productData?.top5?.map((d, index) => (
                        <tr key={d?.id}>
                          <td>{index + 1}</td>
                          <td>{d?.product?.name}</td>
                          <td>{d?.productCount}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>

      <div className="top-selling-product-modal-holder">
        <Modal
          className="top-selling-product-modal"
          show={showTopSellers}
          onHide={handleTopSellersClose}
          animation={false}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Top sellers</Modal.Title>
          </Modal.Header>
          {/* <Button className="dounload-btn-holder ms-2">
            <FontAwesomeIcon
              icon="fa-solid fa-download"
              className="pdf-icon me-2"
              variant="success"
              // className="btn btn-view"
            />{" "}
            <p>Download</p>
          </Button> */}
          <Modal.Body>
            <div className="top-selling-product-modal-content-holder">
              <div className="top-selling-product-dropdown-holder mb-3">
                {/* <Select options={topSellingOptions} /> */}
                <Select
                  className="mb-3"
                  options={reportAnalysis}
                  placeholder={"Select Days"}
                  // value={defaultOption}
                  onChange={(selectedOption) =>
                    GetSellers(selectedOption?.name)
                  }
                />
              </div>
              <br />
              <div className="top-selling-table-holder mb-4">
                <div className="table-heading-holder mb-3">
                  <h3>Top seller</h3>
                </div>
                <div className="table-responsive">
                  <Table bordered responsive="xl">
                    <thead>
                      <tr>
                        <th>Sr.no</th>
                        <th>Seller Name</th>
                        <th>Count</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>{sellers?.top1?.seller?.first_name}</td>
                        <td>{sellers?.top1?.productCount}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>

              <div className="top-five-selling-table-holder mb-4">
                <div className="table-heading-holder mb-3">
                  <h3>Top five seller</h3>
                </div>
                <div className="table-responsive">
                  <Table bordered responsive="xl">
                    <thead>
                      <tr>
                        {" "}
                        <th>Sr.no</th>
                        <th>Seller Name</th>
                        <th>Count</th>
                      </tr>
                    </thead>
                    <tbody>
                      {sellers?.top5?.map((d, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{d?.seller?.first_name}</td>{" "}
                          <td>{d.productCount}</td>{" "}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* <SubscriptionModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        /> */}
      </div>
    </>
  );
};

export default AdvanceDashboard;
